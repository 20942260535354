<template>
    <div class="section" v-if="!loading">
        <div class="section-header card">
            <h2 class="section-header__title">{{ route.meta.title }} #{{ order.number }}</h2>
        </div>

        <div class="row">
            <div class="col-xl-6">
                <div class="card">
                    <h3 class="card__title">Main info</h3>

                    <p class="card__info"><b>Name:</b> {{ order.name }}</p>
                    <p class="card__info"><b>Email:</b> {{ order.email }}</p>
                    <p class="card__info"><b>Comment:</b> {{ order.comment }}</p>
                    <p class="card__info"><b>Status:</b> {{ order.status }}</p>
                    <p class="card__info"><b>Created:</b> {{ timestamp(order.created_at) }}</p>
                </div>

                <div class="card" v-if="order.user">
                    <h3 class="card__title">User</h3>
                    <p class="card__info"><b>Nickname:</b> {{ order.user.nickname }}</p>
                    <p class="card__info"><b>Email:</b> {{ order.user.email }}</p>
                </div>

                <div class="card">
                    <h3 class="card__title">Contacts</h3>

                    <p class="card__info" v-for="contact in order.contacts" :key="contact.id">
                        <span style="text-transform: capitalize; font-weight:bold;">{{ contact.type }}:</span>
                        {{ contact.value }}
                        {{ contact.default ? '(default)' : '' }}
                    </p>
                </div>

                <div class="card" v-if="order.promocode">
                    <h3 class="card__title">Promocode</h3>
                    <p class="card__info"><b>Code:</b> {{ order.promocode.code }}</p>
                    <p class="card__info"><b>Sale:</b> {{ percent(order.promocode.sale) }}</p>
                </div>
            </div>

            <div class="col-xl-6">
                <div class="card">
                    <h3 class="card__title">Summary</h3>
                    <product-card :data="product" v-for="product in order.products" :key="product.id"/>
                    <p class="card__info"><b>Subtotal:</b> {{ euro(subtotal) }}</p>
                    <p class="card__info"><b>Total sale:</b> {{ euro(saleTotal) }}</p>
                    <p class="card__info"><b>Total:</b> {{ euro(total) }}</p>
                </div>
            </div>
        </div>
    </div>
    <loader v-else/>
</template>

<script>
import { ref } from "vue";
import { useRoute } from "vue-router";

import { timestamp, euro } from "@/filters";
import { getOrderById } from "../api/orders";

import ProductCard from "../components/ProductCard";
import Loader from "../components/Loader";

export default {
    name: "Order",
    components: {
        ProductCard,
        Loader,
    },
    setup() {
        const route = useRoute();
        const id = route.params.id;

        const loading = ref(true);
        const order = ref({});

        const subtotal = ref(0);
        const saleTotal = ref(0);
        const total = ref(0);

        getOrderById(id).then((response) => {
            order.value = response.data;
            subtotal.value = order.value.products.reduce((accumulator, product) => accumulator + product.price, 0);
            saleTotal.value = order.value.products.reduce((accumulator, product) => {
                if (product.sale_price) {
                    return accumulator + (product.price - product.sale_price);
                } else {
                    return accumulator;
                }
            }, 0);
            total.value = subtotal.value - saleTotal.value;

            loading.value = false;
        });

        const percent = (value) => {
            return `${value}%`;
        };

        return { loading, route, order, timestamp, subtotal, saleTotal, total, percent, euro };
    },
}
</script>