import { getNested } from "@/utils";

const fields = {
    resource: ['type', 'vendor', 'name', 'description', 'section_id', 'seller_id', 'selling_type'],
    account: ['armory_link', 'safearmory_link'],
    credentials: ['email', 'email_password', 'password', 'secret_question', 'secret_question_answer'],
    login_history: ['name', 'computer', 'vpn_software', 'country', 'vpn_id', 'datetime'],
};

export const diff = (original, changed) => {
    return {
        resource: resourceDiff(original, changed),
        account: accountDiff(original, changed),
        credentials: credentialsDiff(original, changed),
        // login_history: loginDiff(original, changed),
    };
}

const resourceDiff = (original, changed) => {
    const changes = [];

    fields.resource.forEach((field) => {
        const originalField = original.resource[field];
        const changedField = changed.resource[field];

        if (originalField !== changedField) {
            changes.push({
                action: 'edit',
                key: field,
                old: originalField,
                new: changedField,
            });
        }
    });

    return changes;
}

const accountDiff = (original, changed) => {
    const changes = [];

    fields.account.forEach((field) => {
        const originalField = original.account[field];
        const changedField = changed.account[field];

        if (originalField !== changedField) {
            changes.push({
                action: 'edit',
                key: field,
                old: originalField,
                new: changedField,
            });
        }
    });

    return changes;
}

const credentialsDiff = (original, changed) => {
    const changes = {
        'old': [],
        'new': [],
    };
    const subkeys = Object.keys(changes);

    subkeys.forEach((subkey) => {
        fields.credentials.forEach((field) => {
            const originalField = getNested(original.credentials, `${subkey}.${field}`);
            const changedField = getNested(changed.credentials, `${subkey}.${field}`);

            if (originalField !== changedField) {
                changes[subkey].push({
                    action: 'edit',
                    key: field,
                    old: originalField,
                    new: changedField,
                });
            }
        });
    });

    return changes;
}

const loginDiff = (original, changed) => {
    const changes = [];

    original.login_history.forEach((login) => {
        const changedLogin = changed.login_history.filter((item) => item.id === login.id);

        if (!changedLogin.length) {
            changes.push({
                action: 'delete',
                target: login,
            });
            return true;
        } else {
            fields.login_history.forEach((field) => {
                const originalField = login[field];
                const changedField = changedLogin[0][field];

                if (originalField !== changedField) {
                    changes.push({
                        action: 'edit',
                        target: login,
                        key: field,
                        old: originalField,
                        new: changedField,
                    });
                }
            });
        }
    });

    return changes;
}