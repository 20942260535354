import api from '@/api';

// games
export const getGames = () => {
    return api.get('/additional/games');
}

export const showGame = (id) => {
    return api.get(`/additional/games/${id}`);
}

export const storeGame = (data) => {
    return api.post('/additional/games', data);
}

export const updateGame = (id, data) => {
    return api.patch(`/additional/games/${id}`, data);
}

export const destroyGame = (id) => {
    return api.delete(`/additional/games/${id}`);
}

// regions
export const indexRegions = () => {
    return api.get('/additional/regions');
}

export const showRegion = (id) => {
    return api.get(`/additional/regions/${id}`);
}

export const storeRegion = (data) => {
    return api.post('/additional/regions', data);
}

export const updateRegion = (id, data) => {
    return api.patch(`/additional/regions/${id}`, data);
}

export const destroyRegion = (id) => {
    return api.delete(`/additional/regions/${id}`);
}

// sections
export const getSections = () => {
    return api.get('/additional/sections');
}

export const showSection = (id) => {
    return api.get(`/additional/sections/${id}`);
}

export const newSection = (data) => {
    return api.post('/additional/sections', data);
}

export const updateSection = (id, data) => {
    return api.patch(`/additional/sections/${id}`, data);
}

export const destroySection = (id) => {
    return api.delete(`/additional/sections/${id}`);
}