export const timestamp = (value) => {
    if (!value) return null;
    const date = new Date(value);
    return Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
    }).format(date);
};

export const dateToBoolean = (value) => {
    return value !== null;
}

export const euro = (value) => {
    return `${value}€`;
}