<template>
    <table class="table">
        <thead class="table__header">
            <tr>
                <td class="table__header-item" v-for="(header, index) in headers" :key="index">{{ header.label }}</td>
                <td class="table__header-item" v-if="deleted">Deleted at</td>
            </tr>
        </thead>
        <tbody v-if="!loading">
            <router-link :to="link ? `${route.fullPath}/${item.id}` : ''" class="table__row" v-for="(item, i) in items" :key="item.id" :class="{'table__row_deleted': deleted}">
                <td v-for="(header, index) in headers" :key="index" :class="{'td_actions': header.type === 'actions' || header.type === 'drag'}">
                    <template v-if="header.type === 'standard'">
                        {{ item[header.field] !== null ? item[header.field] : 'NULL' }}
                    </template>
                    <template v-if="header.type === 'checkbox'">
                        <input type="checkbox">
                    </template>
                    <template v-if="header.type === 'image'">
                        <img :src="`${item[header.field]}`" alt="" class="table__image">
                    </template>
                    <template v-if="header.type === 'nested'">
                        {{ getNested(item, header.field) }}
                    </template>
                    <template v-if="header.type === 'timestamp'">
                        {{ timestamp(item[header.field]) }}
                    </template>
                    <template v-if="header.type === 'actions'">
                        <div class="table__actions-wrapper">
                            <img src="@/assets/svg/actions.svg" alt="" class="table__actions-icon" @click="setActiveAction(i)">
                            <div class="table__actions" :class="{'table__actions_active': activeAction === i}">
                                <div class="table__action" @click="$emit('edit', item.id); activeAction = null">
                                    Edit
                                    <img src="@/assets/svg/actions-edit.svg" alt="">
                                </div>
                                <div class="table__action" @click="$emit('delete', item.id); activeAction = null">
                                    Delete
                                    <img src="@/assets/svg/actions-delete.svg" alt="">
                                </div>
                            </div>
                        </div>
                    </template>
                    <template v-if="header.type === 'boolean'">
                        {{ item[header.field] ? 'Yes' : 'No' }}
                    </template>
                    <template v-if="header.type === 'drag'">
                        <div class="table__drag-wrapper">
                            <img src="../../assets/svg/move.svg" alt="" draggable="false">
                        </div>
                    </template>
                </td>
                <td v-if="deleted">
                    {{ timestamp(item.deleted_at) }}
                </td>
            </router-link>
        </tbody>
    </table>
    <div class="table__loader" v-if="loading">
        Loading...
    </div>
    <div class="table__empty" v-else-if="!items.length">
        Empty
    </div>
</template>

<script>
import { ref, toRefs } from "vue";
import { useRoute } from "vue-router";

import { getNested } from "@/utils";
import { timestamp } from "@/filters";

export default {
    name: "Table",
    props: {
        headers: {
            type: Array,
        },
        items: {
            type: Array,
            required: true,
        },
        loading: {
            type: Boolean,
        },
        link: {
            type: Boolean,
        },
        deleted: {
            type: Boolean,
        },
    },
    emits: ['edit', 'delete'],
    setup(props) {
        const route = useRoute();

        const deleted = toRefs(props).deleted;

        const activeAction = ref(null);
        const setActiveAction = (index) => {
            activeAction.value = activeAction.value === index ? null : index;
        }

        return { deleted, getNested, route, timestamp, activeAction, setActiveAction };
    },
    filters: {
        timestamp,
    }
}
</script>

<style lang="scss">
.table {
    width: 100%;
    border-collapse: collapse;
}

.table__header {
    height: 60px;
    border-bottom: 1px solid $type-accent;

    td {
        padding: 15px;
    }
}

.table__header-item {
    padding: 10px 0;
    color: $type-accent;
}

.table__row {
    color: $type-dark;
    display: table-row;
    height: 60px;
    cursor: pointer;
    font-size: $t3;

    &:not(:last-child) {
        border-bottom: 1px solid $type-light;
    }

    &:hover {
        background-color: rgba($type-accent, .04);
    }

    td {
        vertical-align: middle;
        padding: 15px;
    }
}

.table__row_deleted {
    opacity: .6;
}

.table__image {
    max-width: 200px;
}

.table__loader {
    padding: 15px;
    text-align: center;
}

.table__empty {
    padding: 15px;
    text-align: center;
}

.table__drag-wrapper {
    img {
        @extend %pointer-no-select;
    }
}

.table__actions-wrapper {
    position: relative;
}

.table__actions-icon {
    @extend %pointer-no-select;
    margin: auto;
    padding: 5px;
}

.table__actions {
    background: $main-white;
    border: 1px solid $type-accent;
    box-sizing: border-box;
    border-radius: 4px;
    position: absolute;
    width: 90px;
    left: -100px;
    top: 0;
    display: none;
}

.table__actions_active {
    display: block;
}

.table__action {
    @extend %flex-jcsb-aic;
    padding: 7px 10px;
    &:hover {
        background-color: rgba($type-accent, .04);
    }
}

.td_actions {
    width: 50px;
}
</style>