import api from '@/api';

export const indexProducts = (page = 1, filter = {}) => {
    return api.get('/products', { params: { page: page, ...filter }});
}

export const showProduct = (id) => {
    return api.get(`/products/${id}`);
}

export const storeProduct = (data) => {
    return api.post('/products', data);
}

export const updateProduct = (id, data) => {
    return api.patch(`/products/${id}`, data);
}