<template>
    <div class="section">
        <div class="section-header card">
            <h2 class="section-header__title">{{ route.meta.title }}{{ showDeleted ? ' [DELETED]' : '' }}</h2>
            <div class="list-buttons" v-if="!['orders', 'buy-requests', 'sell-requests', 'custom-requests', 'contact-us-requests', 'users'].includes(route.name)">
                <router-link :to="`${route.fullPath}/new`">
                    <v-button>Add new</v-button>
                </router-link>
                <v-button outline @click="toggleDeleted">{{ showDeleted ? 'Show active' : 'Show deleted' }}</v-button>
            </div>
        </div>

        <div class="card card_no-padding">
            <v-table :headers="headers" :items="filteredItems" :loading="loading" :link="link" :deleted="showDeleted"/>
        </div>
    </div>
</template>

<script>
import { ref, computed } from 'vue';
import { useRoute } from 'vue-router';

import { getAccounts as getResourceAccounts } from '../api/resource';
import { indexProducts } from '../api/product';
import { indexUsers } from "../api/users";
import { getSellers } from "../api/seller";
import { getOrders } from "../api/orders";
import { getRequests } from "../api/buyRequests";
import { getCustomRequests } from "../api/custom-requests";
import { getSellRequests } from "../api/sell-requests";
import { getContactUsRequests } from "../api/contact-us-requests";

import vTable from "../components/UI/Table.vue";
import vButton from '../components/UI/Button.vue';

export default {
    name: "List",
    components: {
        vTable,
        vButton,
    },
    props: {
        alias: {
            type: String,
            required: true,
        },
        link: {
            type: Boolean,
            default: false,
        },
    },
    setup(props) {
        const loading = ref(true);
        const route = useRoute();

        let headers;
        let func;

        switch (props.alias) {
            case "resources:accounts":
                headers = [
                    {
                        type: 'standard',
                        label: 'Vendor',
                        sorting: true,
                        filter: true,
                        field: 'vendor',
                    },
                    {
                        type: 'standard',
                        label: 'Name',
                        sorting: true,
                        filter: true,
                        field: 'name',
                    },
                    {
                        type: 'standard',
                        label: 'Description',
                        sorting: true,
                        filter: true,
                        field: 'description',
                    },
                    {
                        type: 'standard',
                        label: 'Selling type',
                        sorting: true,
                        filter: true,
                        field: 'selling_type',
                    },
                    {
                        type: 'standard',
                        label: 'Status',
                        sorting: true,
                        filter: true,
                        field: 'status',
                    },
                    {
                        type: 'timestamp',
                        label: 'Created',
                        sorting: true,
                        filter: true,
                        field: 'created_at',
                    },
                    {
                        type: 'timestamp',
                        label: 'Updated',
                        sorting: true,
                        filter: true,
                        field: 'updated_at',
                    },
                ];
                func = getResourceAccounts;
                break;
            case "products":
                headers = [
                    {
                        type: 'standard',
                        label: 'Name',
                        sorting: true,
                        filter: true,
                        field: 'title',
                    },
                    {
                        type: 'standard',
                        label: 'Alias',
                        sorting: true,
                        filter: true,
                        field: 'alias',
                    },
                    {
                        type: 'nested',
                        label: 'Region',
                        sorting: true,
                        filter: true,
                        field: 'region.name',
                    },
                    {
                        type: 'nested',
                        label: 'Game',
                        sorting: true,
                        filter: true,
                        field: 'game.name',
                    },
                    {
                        type: 'nested',
                        label: 'Section',
                        sorting: true,
                        filter: true,
                        field: 'section.name',
                    },
                    {
                        type: 'standard',
                        label: 'Status',
                        sorting: false,
                        filter: false,
                        field: 'status',
                    },
                    {
                        type: 'timestamp',
                        label: 'Created',
                        sorting: false,
                        filter: false,
                        field: 'created_at',
                    },
                    {
                        type: 'timestamp',
                        label: 'Updated',
                        sorting: false,
                        filter: false,
                        field: 'updated_at',
                    },
                    {
                        type: 'actions',
                        label: null,
                        sorting: false,
                        filter: false,
                        field: null,
                    },
                ];
                func = indexProducts;
                break;
            case "users":
                headers = [
                    {
                        type: 'standard',
                        label: 'Nickname',
                        field: 'nickname',
                    },
                    {
                        type: 'standard',
                        label: 'Email',
                        field: 'email',
                    },
                    {
                        type: 'timestamp',
                        label: 'Email verified at',
                        field: 'email_verified_at',
                    },
                    {
                        type: 'timestamp',
                        label: 'Last login',
                        field: 'last_login',
                    }
                ];
                func = indexUsers;
                break;
            case "sellers":
                headers = [
                    {
                        type: 'standard',
                        label: 'Name',
                        sorting: true,
                        filter: true,
                        field: 'name',
                    },
                    {
                        type: 'standard',
                        label: 'Surname',
                        sorting: true,
                        filter: true,
                        field: 'surname',
                    },
                    {
                        type: 'standard',
                        label: 'Country',
                        sorting: true,
                        filter: true,
                        field: 'country',
                    }
                ];
                func = getSellers;
                break;
            case "orders":
                headers = [
                    {
                        type: 'standard',
                        label: 'Number',
                        sorting: true,
                        filter: true,
                        field: 'number',
                    },
                    {
                        type: 'standard',
                        label: 'Name',
                        sorting: true,
                        filter: true,
                        field: 'name',
                    },
                    {
                        type: 'standard',
                        label: 'Email',
                        sorting: true,
                        filter: true,
                        field: 'email',
                    },
                    {
                        type: 'standard',
                        label: 'Comment',
                        sorting: true,
                        filter: true,
                        field: 'comment',
                    },
                    {
                        type: 'standard',
                        label: 'Status',
                        sorting: true,
                        filter: true,
                        field: 'status',
                    },
                    {
                        type: 'timestamp',
                        label: 'Created',
                        sorting: true,
                        filter: true,
                        field: 'created_at',
                    },
                ];
                func = getOrders;
                break;
            case "buy-requests":
                headers = [
                    {
                        type: 'standard',
                        label: 'Product',
                        sorting: true,
                        filter: true,
                        field: 'product_id',
                    },
                    {
                        type: 'standard',
                        label: 'Name',
                        sorting: true,
                        filter: true,
                        field: 'name',
                    },
                    {
                        type: 'standard',
                        label: 'Status',
                        sorting: true,
                        filter: true,
                        field: 'status',
                    },
                    {
                        type: 'timestamp',
                        label: 'Created',
                        sorting: true,
                        filter: true,
                        field: 'created_at',
                    },
                ];
                func = getRequests;
                break;
            case "custom-requests":
                headers = [
                    {
                        type: 'standard',
                        label: 'Name',
                        sorting: true,
                        filter: true,
                        field: 'name',
                    },
                    {
                        type: 'standard',
                        label: 'Budget',
                        sorting: true,
                        filter: true,
                        field: 'budget',
                    },
                    {
                        type: 'standard',
                        label: 'Status',
                        sorting: true,
                        filter: true,
                        field: 'status',
                    },
                    {
                        type: 'standard',
                        label: 'Status',
                        sorting: true,
                        filter: true,
                        field: 'status',
                    },
                    {
                        type: 'timestamp',
                        label: 'Created',
                        sorting: true,
                        filter: true,
                        field: 'created_at',
                    },
                ];
                func = getCustomRequests;
                break;
            case "sell-requests":
                headers = [
                    {
                        type: 'standard',
                        label: 'Name',
                        sorting: true,
                        filter: true,
                        field: 'name',
                    },
                    {
                        type: 'standard',
                        label: 'Armory link',
                        sorting: true,
                        filter: true,
                        field: 'armory_link',
                    },
                    {
                        type: 'standard',
                        label: 'Original',
                        sorting: true,
                        filter: true,
                        field: 'original',
                    },
                    {
                        type: 'standard',
                        label: 'Has current mail',
                        sorting: true,
                        filter: true,
                        field: 'have_current_mail',
                    },
                    {
                        type: 'standard',
                        label: 'No banned licences',
                        sorting: true,
                        filter: true,
                        field: 'no_banned_licences',
                    },
                    {
                        type: 'standard',
                        label: 'No hacks',
                        sorting: true,
                        filter: true,
                        field: 'no_hacks',
                    },
                    {
                        type: 'standard',
                        label: 'Status',
                        sorting: true,
                        filter: true,
                        field: 'status',
                    },
                    {
                        type: 'timestamp',
                        label: 'Created',
                        sorting: true,
                        filter: true,
                        field: 'created_at',
                    },
                ];
                func = getSellRequests;
                break;
            case "contact-us-requests":
                headers = [
                    {
                        type: 'standard',
                        label: 'Number',
                        sorting: true,
                        filter: true,
                        field: 'number',
                    },
                    {
                        type: 'standard',
                        label: 'User',
                        sorting: true,
                        filter: true,
                        field: 'user_id',
                    },
                    {
                        type: 'standard',
                        label: 'Email',
                        sorting: true,
                        filter: true,
                        field: 'email',
                    },
                    {
                        type: 'standard',
                        label: 'Subject',
                        sorting: true,
                        filter: true,
                        field: 'subject',
                    },
                    {
                        type: 'timestamp',
                        label: 'Created',
                        sorting: true,
                        filter: true,
                        field: 'created_at',
                    },
                ];
                func = getContactUsRequests;
                break;
        }

        const items = ref([]);
        func().then((response) => {
            items.value = response.data;
            loading.value = false;
        });

        const filteredItems = computed(() => {
            return items.value.filter((item) => (showDeleted.value ? item.deleted_at : !item.deleted_at));
        });

        const showDeleted = ref(false);
        const toggleDeleted = () => {
            showDeleted.value = !showDeleted.value;
        }

        return { route, headers, items, filteredItems, loading, showDeleted, toggleDeleted };
    },
}
</script>

<style lang="scss">
.list-buttons {
    margin-left: auto;
    margin-right: -10px;

    > .btn, > a {
        margin-right: 10px;
    }
}
</style>