<template>
    <div class="products-filter card">
        <form action="#">
            <div class="products-filter__wrapper">
                <div class="products-filter__item">
                    <v-input v-model="data.vendor" placeholder="Vendor"/>
                </div>
                <div class="products-filter__item">
                    <v-input v-model="data.title" placeholder="Title"/>
                </div>
                <div class="products-filter__item">
                    <v-input v-model="data.alias" placeholder="Alias"/>
                </div>
                <div class="products-filter__item">
                    <v-select :options="games" v-model="data.game_id"/>
                </div>
                <div class="products-filter__item">
                    <v-button @click="submit">Apply</v-button>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import { ref } from "vue";
import { useRoute, useRouter } from "vue-router";

import { getGames } from "../api/additional";

import { filterObjectByFalsy } from "../utils";

import vInput from "./UI/Input";
import vSelect from "./UI/Select";
import vButton from "./UI/Button";

export default {
    name: "ProductsFilter",
    components: {
        vInput,
        vSelect,
        vButton,
    },
    setup() {
        const route = useRoute();
        const router = useRouter();

        const data = ref({
            vendor: route.query.vendor || '',
            title: route.query.title || '',
            alias: route.query.alias || '',
            game_id: route.query.game_id || '',
        });

        const games = ref([]);
        getGames().then((response) => {
            const emptyValue = {
                name: '(Game)',
                id: '',
            };
            const gameOptions = response.data;
            gameOptions.unshift(emptyValue);

            games.value = gameOptions;
        });

        const submit = () => {
            const query = filterObjectByFalsy(data.value);
            router.push({ query });
        };

        return { data, games, submit };
    },
}
</script>

<style lang="scss" scoped>
.products-filter__wrapper {
    @extend %flex-aic;
}

.products-filter__item {
    &:not(:last-child) {
        margin-right: 10px;
    }
}
</style>