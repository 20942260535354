import api from '@/api';

export const getAccounts = () => {
    return api.get('/resources/accounts');
}

export const getAccountById = (id) => {
    return api.get(`/resources/accounts/${id}`);
}

export const newAccount = (data) => {
    return api.post('/resources/accounts', data);
}

export const editAccount = (id, data) => {
    return api.patch(`/resources/accounts/${id}`, data);
}