<template>
    <div class="section" v-if="!loading">
        <div class="section-header card">
            <h2 class="section-header__title">{{ route.meta.title }}</h2>
        </div>

        <div class="row">
            <div class="col-xl-6">
                <div class="card">
                    <h3 class="card__title">Main info</h3>
                    <p class="card__info">Name: {{ customRequest.name }}</p>
                    <p class="card__info">Game: {{ customRequest.game }}</p>
                    <p class="card__info">Region: {{ customRequest.region }}</p>
                    <p class="card__info">Type of service: {{ customRequest.section }}</p>
                    <p class="card__info">Budget: €{{ customRequest.budget }}</p>
                    <p class="card__info">Status: {{ customRequest.status }}</p>
                    <p class="card__info">Created: {{ timestamp(customRequest.created_at) }}</p>
                </div>

                <div class="card" v-if="customRequest.user">
                    <h3 class="card__title">User</h3>
                    <p class="card__info">Nickname: {{ customRequest.user.nickname }}</p>
                    <p class="card__info">Email: {{ customRequest.user.email }}</p>
                </div>
            </div>
            <div class="col-xl-6">
                <div class="card">
                    <h3 class="card__title">Contacts</h3>

                    <p class="card__info" v-for="contact in customRequest.contacts" :key="contact.id">
                        <span style="text-transform: capitalize">{{ contact.type }}:</span>
                        {{ contact.value }}
                        {{ contact.default ? '(default)' : '' }}
                    </p>
                </div>
            </div>
        </div>
    </div>
    <loader v-else/>
</template>

<script>
import { ref } from "vue";
import { useRoute } from "vue-router";

import { timestamp } from "../filters";
import { getCustomRequestById } from "../api/custom-requests";

import Loader from "../components/Loader";

export default {
    name: "CustomRequest",
    components: {
        Loader,
    },
    setup() {
        const route = useRoute();
        const id = route.params.id;

        const loading = ref(true);
        const customRequest = ref({});

        getCustomRequestById(id).then((response) => {
            customRequest.value = response.data;
            loading.value = false;
        });

        return { loading, route, customRequest, timestamp };
    },
}
</script>

<style scoped>

</style>