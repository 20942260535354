<template>
    <div class="product">
        <div class="product__image">
            <img :src="`${ data.image }`" alt="">
        </div>
        <div class="product__info">
            <h3 class="product__title">{{ data.title }}</h3>
            <p class="product__info-item"><b>Vendor:</b> {{ data.vendor }}</p>
            <p class="product__info-item"><b>Game:</b> {{ data.game.name }}</p>
            <p class="product__info-item"><b>Region:</b> {{ data.region.name }}</p>
            <p class="product__info-item"><b>Section:</b> {{ data.section.name }}</p>
            <p class="product__info-item" v-if="!data.sale_price"><b>Price:</b> {{ euro(data.price) }}</p>
            <p class="product__info-item" v-else><b>Price:</b> {{ euro(data.sale_price) }} (old price: {{ euro(data.price) }}) </p>
        </div>
        <router-link :to="adminLink" target="_blank">
            <v-button>Show in admin</v-button>
        </router-link>
        <a :href="clientLink" target="_blank">
            <v-button outline>Show in client</v-button>
        </a>
    </div>
</template>

<script>
import { euro } from "@/filters";

import vButton from "./UI/Button";

export default {
    name: "ProductCard",
    components: {
        vButton,
    },
    props: {
        data: {
            type: Object,
            required: true,
        },
    },
    setup(props) {
        const adminLink = `/products/${props.data.id}`;
        const clientLink = `https://safepoint.cc/${props.data.game.alias}-${props.data.region.alias}/${props.data.section.alias}/${props.data.number}/${props.data.alias}`;

        return { adminLink, clientLink, euro };
    },
}
</script>

<style lang="scss" scoped>
.product {
    @extend %flex-jcsb-aic;
    padding: 10px;
    border: 1px solid #55649b;
    &:not(:last-child) {
        margin-bottom: 10px;
    }
}

.product__image {
    max-width: 20%;
    margin-right: 20px;
}

.product__title {
    margin-bottom: 20px;
}

.product__info {
    margin-right: 20px;
}

.product__info-item {
    &:not(:last-child) {
        margin-bottom: 5px;
    }
}
</style>