<template>
    <div class="error">
        <h1 class="error__code">{{ code }}</h1>
        <h2 class="error__message">{{ message }}</h2>
    </div>
</template>

<script>
export default {
    name: "ErrorWrapper",
    props: ['code', 'message'],
    setup(props) {
        console.log(props);
    }
}
</script>

<style lang="scss">
    .error {
        @extend %flex-jcc-aic;
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: $main-white;
    }

    .error__code {
        font-size: $h1;
        margin-right: 20px;
    }
</style>