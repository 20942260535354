<template>
    <div class="pagination">
        <div
            class="pagination__item"
            :class="{ 'pagination__item_disabled': pagination.current_page === 1}"
            @click="goTo(1)"
        >
            &lt;&lt;
        </div>
        <div
            class="pagination__item"
            :class="{ 'pagination__item_disabled': pagination.current_page === 1}"
            @click="goTo(pagination.current_page - 1)"
        >
            &lt;
        </div>
        <div class="pagination__item">
            {{ pagination.current_page || 1 }}
        </div>
        <div
            class="pagination__item"
            :class="{ 'pagination__item_disabled': pagination.current_page === pagination.total_pages}"
            @click="goTo(pagination.current_page + 1)"
        >
            &gt;
        </div>
        <div
            class="pagination__item"
            :class="{ 'pagination__item_disabled': pagination.current_page === pagination.total_pages}"
            @click="goTo(pagination.total_pages)"
        >
            &gt;&gt;
        </div>
    </div>
</template>

<script>
import { useRoute, useRouter } from "vue-router";

export default {
    name: "Pagination",
    props: {
        pagination: {
            type: Object,
            required: true,
        },
    },
    setup() {
        const route = useRoute();
        const router = useRouter();

        const goTo = (page) => {
            const query = Object.assign({}, route.query);
            query.page = page;
            router.push({ query });
        }

        return { goTo };
    },
}
</script>

<style lang="scss" scoped>
.pagination {
    @extend %flex-jcc-aic;

    &__item {
        padding: 10px;
        font-size: $t1;
        cursor: pointer;
        &_disabled {
            pointer-events: none;
            color: $type-dark-gray;
        }
    }
}
</style>