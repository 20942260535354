<template>
    <div class="section">
        <div class="section-header card">
            <h2 class="section-header__title">{{route.meta.title}}</h2>
            <v-button @click="submit">Submit</v-button>
        </div>

        <div class="row" v-if="!loading">
            <div class="col-8">
                <div class="card card_grid">
                    <h3 class="card__title">Seller</h3>
                    <div class="row row-form">
                        <div class="col-4">
                            <div class="form-group">
                                <label class="label">Name</label>
                                <v-input v-model="seller.name"/>
                            </div>
                        </div>
                        <div class="col-4">
                            <div class="form-group">
                                <label class="label">Surname</label>
                                <v-input v-model="seller.surname"/>
                            </div>
                        </div>
                        <div class="col-4">
                            <div class="form-group">
                                <label class="label">Patronymic (optional)</label>
                                <v-input v-model="seller.patronymic"/>
                            </div>
                        </div>
                        <div class="col-4">
                            <div class="form-group">
                                <label class="label">Country</label>
                                <v-input v-model="seller.country"/>
                            </div>
                        </div>
                    </div>

                    <div class="card__divider"/>

                    <div class="card__subtitle">Passport</div>
                    <div class="row row-form">
                        <div class="col-4">
                            <div class="form-group">
                                <label class="label">Series and №</label>
                                <v-input v-model="seller.psan"/>
                            </div>
                        </div>
                        <div class="col-4">
                            <div class="form-group">
                                <label class="label">Issued by</label>
                                <v-input v-model="seller.ib"/>
                            </div>
                        </div>
                        <div class="col-4">
                            <div class="form-group">
                                <label class="label">Date of Issue</label>
                                <v-input type="date" v-model="seller.doi"/>
                            </div>
                        </div>
                        <div class="col-4">
                            <div class="form-group">
                                <label class="label">Department Code</label>
                                <v-input v-model="seller.dc"/>
                            </div>
                        </div>
                        <div class="col-4">
                            <div class="form-group">
                                <label class="label">Date of Birth</label>
                                <v-input type="date" v-model="seller.dob"/>
                            </div>
                        </div>
                        <div class="col-4">
                            <div class="form-group">
                                <label class="label">Place of Birth</label>
                                <v-input v-model="seller.pob"/>
                            </div>
                        </div>
                    </div>

                    <div class="card__divider"/>

                    <div class="card__subtitle">Address</div>
                    <div class="row row-form">
                        <div class="col-6">
                            <div class="form-group">
                                <label class="label">Registered</label>
                                <v-input v-model="seller.address_registered"/>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-group">
                                <label class="label">Current</label>
                                <v-input v-model="seller.address_current"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-4">
                <div class="card card_grid">
                    <h3 class="card__title">Contacts</h3>
                    [[ NOT READY ]]
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue';
import { useRoute, useRouter } from "vue-router";

import {editSeller, getSellerById, newSeller} from '@/api/seller';

import vInput from '@/components/UI/Input.vue';
import vSelect from '@/components/UI/Select.vue';
import vButton from '@/components/UI/Button.vue';

export default {
    name: "Seller",
    components: {
        vInput,
        vSelect,
        vButton,
    },
    props: ['mode'],
    setup(props) {
        const loading = ref(true);
        const router = useRouter();
        const route = useRoute();

        const seller = ref({
            name: '',
            surname: '',
            patronymic: '',
            country: '',
            psan: '',
            ib: '',
            doi: '',
            dc: '',
            dob: '',
            pob: '',
            address_registered: '',
            address_current: '',
        });

        if (props.mode === 'new') {
            loading.value = false;
        } else {
            const id = route.params.id;
            getSellerById(id).then((response) => {
                seller.value = response.data;
                loading.value = false;
            });
        }

        const submit = () => {
            if (props.mode === 'new') {
                newSeller(seller.value).then(() => {
                    router.back();
                })
            } else {
                editSeller(route.params.id, seller.value).then(() => {
                    router.back();
                });
            }
        }

        return { route, loading, seller, submit };
    },
}
</script>