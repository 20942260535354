<template>
    <div class="section" v-if="!loading">
        <div class="section-header card">
            <h2 class="section-header__title">{{ route.meta.title }}</h2>
            <div class="section-header__right">
                <div class="section-header__checkbox-group">
                    <v-checkbox v-model="data.product.public">Public</v-checkbox>
                    <v-checkbox v-model="data.product.show_price">Show price</v-checkbox>
                </div>
                <v-button @click="submit" class="section-header__submit">Submit</v-button>
            </div>
        </div>

        <div class="row">
            <div class="col-8">
                <div class="card card_grid">
                    <h3 class="card__title">Product</h3>
                    <p class="card__subtitle">Identification</p>
                    <div class="row row-form">
                        <div class="col-6">
                            <div class="form-group">
                                <label class="label">Vendor</label>
                                <v-input v-model="data.product.vendor"/>
                                <span class="form-error" v-if="errors['vendor']">{{ errors['vendor'][0] }}</span>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-group">
                                <label class="label">Status</label>
                                <v-select :options="[{id: 'active', name: 'Active'}, {id: 'pending', name: 'Pending'}, {id: 'sold', name: 'Sold'}]" v-model="data.product.status"/>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-group">
                                <label class="label">Game</label>
                                <v-select :options="games" v-model="data.product.game_id" @update:modelValue="dropSection"/>
                                <span class="form-error" v-if="errors['game_id']">{{ errors['game_id'][0] }}</span>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-group">
                                <label class="label">Region</label>
                                <v-select :options="regions" v-model="data.product.region_id" @update:modelValue="dropSection"/>
                                <span class="form-error" v-if="errors['region_id']">{{ errors['region_id'][0] }}</span>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-group">
                                <label class="label">Section</label>
                                <v-select :options="filteredSections" v-model="data.product.section_id" @update:modelValue="selectSection"/>
                                <span class="form-error" v-if="errors['section_id']">{{ errors['section_id'][0] }}</span>
                            </div>
                        </div>
                      <div class="col-6">
                        <div class="form-group">
                          <label class="label">G2G Country</label>
                          <v-select :options="countries" v-model="data.product.country" value="name"/>
                          <span class="form-error" v-if="errors['section_id']">{{ errors['section_id'][0] }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="card__divider"/>

                    <p class="card__subtitle">Complete product card</p>
                    <div class="row row-form">
                        <div class="col-6">
                            <div class="form-group">
                                <label class="label">Title</label>
                                <v-input v-model="data.product.title" @update:modelValue="data.product.alias = makeAlias(data.product.title)"/>
                                <span class="form-error" v-if="errors['title']">{{ errors['title'][0] }}</span>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-group">
                                <label class="label">Alias</label>
                                <v-input v-model="data.product.alias"/>
                                <span class="form-error" v-if="errors['alias']">{{ errors['alias'][0] }}</span>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="form-group">
                                <label class="label">Main image TODO: add validation</label>
                                <image-uploader folder="products" v-model="data.product.image" :id="data.product.id"/>
                                <span class="form-error" v-if="errors['image']">{{ errors['image'][0] }}</span>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="form-group">
                                <label class="label">Short description</label>
                                <v-textarea rows="10" v-model="data.product.short_description"/>
                                <span class="form-error" v-if="errors['short_description']">{{ errors['short_description'][0] }}</span>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="form-group">
                                <label class="label">Source description</label>
                                <v-textarea rows="25" v-model="data.product.source_description"/>
                                <span class="form-error" v-if="errors['source_description']">{{ errors['source_description'][0] }}</span>
                            </div>
                        </div>
                        <div class="col-12" v-if="data.product.source_description.length">
                            <div class="form-group">
                                <label class="label">Description preview</label>
                                <div class="md-preview" v-html="md"/>
                            </div>
                        </div>
                    </div>

                    <div class="card__divider"/>
                    <p class="card__subtitle">Price information</p>
                    <div class="row">
                        <div class="col-4">
                            <div class="form-group">
                                <label class="label">Price</label>
                                <v-input units="USD" v-model="data.product.price"/>
                                <span class="form-error" v-if="errors['price']">{{ errors['price'][0] }}</span>
                            </div>
                        </div>
                        <div class="col-4">
                            <div class="form-group">
                                <label class="label">Sale price</label>
                                <v-input units="USD" v-model="data.product.sale_price"/>
                                <span class="form-error" v-if="errors['sale_price']">{{ errors['sale_price'][0] }}</span>
                            </div>
                        </div>
                        <div class="col-4">
                            <div class="form-group">
                                <label class="label">Sale</label>
                                <v-input units="%" v-model="data.product.sale"/>
                                <span class="form-error" v-if="errors['sale']">{{ errors['sale'][0] }}</span>
                            </div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label class="label">External link</label>
                        <v-input v-model="data.product.ext_url"/>
                        <span class="form-error" v-if="errors['ext_url']">{{ errors['ext_url'][0] }}</span>
                    </div>
                </div>
            </div>
            <div class="col-4">
                <div class="card card_grid" v-if="data.features.length">
                    <h3 class="card__title">Features</h3>
                    <div class="row">
                        <div class="col-xl-6" v-for="(feature, index) in features">
                            <div class="form-group">
                                <label class="label">{{ feature.key.toUpperCase() }}</label>
                                <v-input v-model="data.features[index].value"/>
                                <span class="form-error" v-if="errors[`features.${index}`]">{{ errors[`features.${index}`][0] }}</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card card_grid" v-if="kamchReady">
                    <h3 class="card__title">Checklist</h3>
                    <div class="form-group">
                        <v-checkbox v-model="data.checklist.items[0].active">G2G</v-checkbox>
                    </div>
                    <div class="form-group">
                        <v-checkbox v-model="data.checklist.items[1].active">EpicNPC</v-checkbox>
                    </div>
                    <div>
                        <v-checkbox v-model="data.checklist.items[2].active">Ownedcore</v-checkbox>
                    </div>
                </div>

                <div class="card card-grid" v-if="kamchReady && isWowAccount">
                    <h3 class="card__title">WoW Account Data</h3>
                    <div class="form-group">
                        <label class="label">Type</label>
                        <v-select v-model="data.wow_account_data.type" :options="types"/>
                    </div>
                    <div class="form-group" v-if="data.wow_account_data.type === 'character_as_transport'">
                        <label class="label">URL</label>
                        <v-input v-model="data.wow_account_data.url"/>
                        <span class="form-error" v-if="errors['url']">{{ errors['url'][0] }}</span>
                    </div>
                    <div>
                        <v-checkbox v-model="data.wow_account_data.show_server">Show server</v-checkbox>
                    </div>
                </div>
            </div>

            <div class="col-6" v-if="kamchReady">
                <div class="card">
                    <h3 class="card__title">Tasks</h3>
                    <table class="table">
                        <thead class="table__header">
                        <tr>
                            <td class="table__header-item">ID</td>
                            <td class="table__header-item">Date</td>
                            <td class="table__header-item">Action</td>
                            <td class="table__header-item">Resource</td>
                            <td class="table__header-item">Status</td>
                            <td class="table__header-item">Actions</td>
                        </tr>
                        </thead>
                        <tbody>
                            <tr class="table__row" v-for="task in tasks" :key="task.id">
                                <td>{{ task.id }}</td>
                                <td>{{ task.created_at }}</td>
                                <td>{{ task.action }}</td>
                                <td>{{ task.resource.name }}</td>
                                <td :title="task.status_text">
                                    {{ task.status }} {{ task.status !== 'done' ? ' (?)' : ''}}
                                </td>
                                <td>
                                    <a href="#" @click.prevent="taskId = task.id">Task results</a><br>
                                    <a href="#" v-if="task.status !== 'done'" @click.prevent="repeatTask(task.id)">Repeat</a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="col-6" v-if="kamchReady">
                <div class="card">
                    <h3 class="card__title">Active posts</h3>
                    <table class="table">
                        <thead class="table__header">
                        <tr>
                            <td class="table__header-item">ID</td>
                            <td class="table__header-item">Resource</td>
                            <td class="table__header-item">Date</td>
                            <td class="table__header-item">Link</td>
                            <td class="table__header-item">Manage link</td>
                        </tr>
                        </thead>
                        <tbody>
                        <tr class="table__row" v-for="activePost in activePosts" :key="activePost.id">
                            <td>{{ activePost.id }}</td>
                            <td>{{ activePost.resource.name }}</td>
                            <td>{{ activePost.created_at }}</td>
                            <td>
                                <a :href="activePost.url" v-if="activePost.url" target="_blank">Link</a>
                            </td>
                            <td>
                                <a :href="activePost.data.manage_url" v-if="activePost.data.manage_url" target="_blank">Link</a>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="col-xl-12" v-if="originalImages.length">
                <div class="card">
                    <h3 class="card__title">Original images</h3>
                    <img :src="originalImage.url" alt="" v-for="originalImage in originalImages">
                </div>
            </div>
        </div>
    </div>
    <loader v-else/>
    <task-results-modal v-if="taskId" :id="taskId" @close-modal="taskId = null"/>
    <draft alias="product" :data="data" v-if="mode !== 'edit'" @apply="applyDraft"/>
</template>

<script>
import { ref, computed } from 'vue';
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { useToast } from "vue-toastification";
import { v4 as uuidv4 } from 'uuid';

import { makeAlias } from "../utils";

import { showProduct, storeProduct, updateProduct } from "../api/product";
import { getGames, getSections } from "../api/additional";
import { getTasks, getActivePosts, repeatTask, getChecklists, getWowAccountData, getOriginalImage } from "../api/kamch";
import { getCountries } from "@/api/country";

import Loader from "../components/Loader";
import vInput from "../components/UI/Input.vue";
import vTextarea from "../components/UI/Textarea.vue";
import vCheckbox from "../components/UI/Checkbox.vue";
import vSelect from "../components/UI/Select.vue";
import vButton from "../components/UI/Button.vue";
import ImageUploader from "../components/UI/ImageUploader.vue";
import Tags from "../components/UI/Tags.vue";
import TaskResultsModal from "../components/Modals/TaskResultsModal";
import Draft from "../components/Draft";

export default {
    name: "Product",
    components: {
        Loader,
        vInput,
        vButton,
        vTextarea,
        vCheckbox,
        vSelect,
        ImageUploader,
        Tags,
        TaskResultsModal,
        Draft,
    },
    props: ['mode', 'type'],
    setup(props) {
        const router = useRouter();
        const route = useRoute();
        const store = useStore();
        const toast = useToast();

        let id = route.params.id;
        let mode = ref(props.mode);

        const data = ref({
            product: {
                id: uuidv4(),
                vendor: '',
                status: 'active',
                country: '',
                game_id: '',
                region_id: '',
                section_id: '',
                resource_id: '',
                title: '',
                alias: '',
                image: '',
                short_description: '',
                source_description: '',
                price: 0,
                sale_price: 0,
                sale: 0,
                ext_url: null,
                public: false,
                show_price: true,
            },
            features: [],
            checklist: {
                items: [
                    {
                        name: 'g2g',
                        active: false,
                    },
                    {
                        name: 'ownedcore',
                        active: false,
                    },
                    {
                        name: 'epicnpc',
                        active: false,
                    },
                ],
            },
            wow_account_data: {
                type: '',
                url: '',
                show_server: false,
            },
        });
        const types = ref([
            {
                id: 'character_as_transport',
                name: 'Character as transport',
            },
            {
                id: 'account',
                name: 'Account',
            },
            {
                id: 'character',
                name: 'Character',
            },
            {
                id: 'character_with_min_characteristics',
                name: 'Character with minimal characteristics',
            },
        ]);

        const loading = ref(true);
        const kamchReady = store.state.kamchReady;

        const games = ref([]);
        const sections = ref([]);
        const resources = ref([]);
        const tasks = ref([]);
        const activePosts = ref([]);
        const taskId = ref(null);
        const originalImages = ref({});
        const countries = ref([]);

        const methods = [getGames(), getSections()];
        if (mode.value === 'edit') {
            methods.push(showProduct(id));
            if (kamchReady) {
                methods.push(getTasks(id));
                methods.push(getActivePosts(id));
                methods.push(getOriginalImage(id));
            }
        }
        methods.push(getCountries());

        Promise.all(methods).then((response) => {
            games.value = response[0].data;
            sections.value = response[1].data;
            countries.value = response.at(-1).data;

            if (mode.value === 'edit') {
                data.value = response[2].data;
                if (kamchReady) {
                    tasks.value = response[3].data;
                    activePosts.value = response[4].data;
                    originalImages.value = response[5].data;

                    getWowAccountData(id).then((response) => {
                        data.value.wow_account_data = response.data;
                    }).catch(() => {
                        data.value.wow_account_data = {
                            type: '',
                            url: '',
                            show_server: false,
                        };
                    });

                    getChecklists(id).then((response) => {
                        data.value.checklist = response.data;
                    }).catch(() => {
                        data.value.checklist = {
                            items: [
                                {
                                    name: 'g2g',
                                    active: false,
                                },
                                {
                                    name: 'ownedcore',
                                    active: false,
                                },
                                {
                                    name: 'epicnpc',
                                    active: false,
                                },
                            ],
                        };
                    });
                }
            }

            loading.value = false;
        }, reason => console.log(reason.response));


        const regions = computed(() => {
            if (!data.value.product.game_id) return [];
            const chosenGame = games.value.filter((game) => game.id === data.value.product.game_id)[0];
            return chosenGame.regions;
        });

        const filteredSections = computed(() => {
            return sections.value.filter((section) => section.game_id === data.value.product.game_id && section.region_id === data.value.product.region_id);
        });

        const selectedSection = computed(() => {
            return filteredSections.value.find((section) => section.id === data.value.product.section_id);
        });

        const isWowAccount = computed(() => {
            const section = selectedSection.value;
            if (section) return ['World of Warcraft', 'WoW Classic', 'WoW BC Classic'].includes(section.game) && section.name === 'Accounts';
        });

        const dropSection = () => {
            data.value.product.section_id = null;
        };

        const selectSection = () => {
            const features = [];
            selectedSection.value.features.forEach((feature) => {
                features.push({
                    key: feature.key,
                    section_feature_id: feature.id,
                    value: '',
                });
            });
            data.value.features = features;
        };


        const md = computed(() => {
            return converter.makeHtml(data.value.product.source_description);
        });

        const features = computed(() => {
            if (mode.value === 'edit') {
                return data.value.features;
            } else {
                return selectedSection.value ? selectedSection.value.features : [];
            }
        });

        const applyDraft = (draft) => {
            data.value = draft;
            data.value.product.id = uuidv4();
        }

        const errors = ref({});

        const submit = () => {
            if (kamchReady) {
                const type = data.value.wow_account_data.type;
                if (type === 'account' || type === 'character') {
                    data.value.wow_account_data.url = data.value.product.ext_url;
                } else if (type === 'character_with_min_characteristics') {
                    data.value.wow_account_data.url = null;
                }
            } else {
                delete data.value.wow_account_data;
                delete data.value.checklist;
            }

            if (!isWowAccount.value) delete data.value.wow_account_data;

            errors.value = {};

            if (mode.value === 'new') {
                storeProduct(data.value).then(() => {
                    toast.success('Product successfully created');
                    router.push('/products');
                }).catch((error) => {
                    if (error.response.status === 422) errors.value = error.response.data;
                    else if (error.response.status === 420) {
                        toast.success('Product successfully created');
                        toast.warning('Resource manager PATCH failed. Please add features manually');
                        errors.value = error.response.data;
                        mode.value = 'edit';
                    } else {
                        toast.error('Unexpected error');
                    }
                });
            } else {
                updateProduct(data.value.product.id, data.value).then(() => {
                    toast.success('Product successfully saved');
                    router.push('/products');
                }).catch((error) => {
                    if (error.response.status === 422) errors.value = error.response.data;
                    else if (error.response.status === 420) {
                        toast.success('Product successfully saved');
                        toast.warning('Resource manager PATCH failed. Please add features manually');
                        errors.value = error.response.data;
                        mode.value = 'edit';
                    } else if (error.response.status === 512) {
                        toast.warning(error.response.data);
                    } else {
                        toast.error('Unexpected error');
                    }
                });
            }
        };

        return {
            route,
            data,
            types,
            makeAlias,
            loading,
            kamchReady,
            games,
            regions,
            countries,
            tasks,
            activePosts,
            taskId,
            originalImages,
            filteredSections,
            selectedSection,
            isWowAccount,
            dropSection,
            selectSection,
            resources,
            md,
            features,
            repeatTask,
            applyDraft,
            errors,
            submit,
        };
    },
}
</script>

<style lang="scss" scoped>
.md-preview {
    border: 1px solid $type-gray;
    padding: 10px;
    ::v-deep(h1), ::v-deep(h2), ::v-deep(h3), ::v-deep(h4) {
        margin-bottom: 10px;
    }
    ::v-deep(ul) {
        padding-left: 20px;
        &:not(:last-child) {
            margin-bottom: 30px;
        }
    }
}
</style>
