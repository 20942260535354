<template>
    <div class="section" v-if="!loading">
        <div class="section-header card">
            <h2 class="section-header__title">{{ route.meta.title }}</h2>
            <p class="section-header__autosave-message" v-if="autosaved">Autosaved at {{ autosavedTime }}</p>

            <div class="section-header__right">
                <v-button @click="submit" class="section-header__submit">Submit</v-button>
            </div>
        </div>

        <div class="row">
            <div class="col-6">
                <!-- resource -->
                <div class="card card_grid">
                    <h3 class="card__title">Resource</h3>
                    <p class="card__subtitle">Identification</p>
                    <div class="row row-form">
                        <div class="col-6">
                            <div class="form-group">
                                <label class="label">Vendor</label>
                                <v-input v-model="data.resource.vendor"/>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-group">
                                <label class="label">Name</label>
                                <v-input v-model="data.resource.name"/>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-group">
                                <label class="label">Section</label>
                                <v-select :options="sections" v-model="data.resource.section_id"/>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-group">
                                <label class="label">Status</label>
                                <v-select :options="[{id: 'draft', name: 'Draft'}, {id: 'active', name: 'Active'}]" v-model="data.resource.status"/>
                            </div>
                        </div>
                        <div class="col">
                            <div class="form-group">
                                <label class="label">Description</label>
                                <v-textarea v-model="data.resource.description"/>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- account -->
                <div class="card">
                    <h3 class="card__title">Account</h3>
                    <p class="card__subtitle">Main info</p>
                    <div class="row row-form">
                        <div class="col-6">
                            <div class="form-group">
                                <label class="label">BattleTag</label>
                                <v-input v-model="data.account.battletag"/>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-group">
                                <label class="label">Addon string</label>
                                <v-input v-model="data.account.addon_string"/>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-group">
                                <label class="label">Official armory link</label>
                                <v-input v-model="data.account.armory_link"/>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-group">
                                <label class="label">SafeArmory link</label>
                                <v-input v-model="data.account.safearmory_link"/>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-group">
                                <label class="label">Wallet balance</label>
                                <v-input v-model="data.account.wallet_balance"/>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-group">
                                <label class="label">Basic licence</label>
                                <v-input v-model="data.account.basic_licence"/>
                            </div>
                        </div>
                    </div>

                    <div class="card__divider"/>

                    <p class="card__subtitle">Initial info</p>
                    <div class="row">
                        <div class="col-6">
                            <div class="form-group">
                                <label class="label">Email</label>
                                <v-input v-model="data.account.email"/>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-group">
                                <label class="label">Email password</label>
                                <v-input v-model="data.account.email_password"/>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-group">
                                <label class="label">Gift-code</label>
                                <v-input v-model="data.account.gift_code"/>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-group">
                                <label class="label">CD-key</label>
                                <v-input v-model="data.account.cd_key"/>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-group">
                                <label class="label">Name</label>
                                <v-input v-model="data.account.name"/>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-group">
                                <label class="label">Surname</label>
                                <v-input v-model="data.account.surname"/>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="form-group">
                                <v-checkbox v-model="data.account.fake">Fake</v-checkbox>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-group">
                                <label class="label">Country</label>
                                <v-input v-model="data.account.country"/>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-group">
                                <label class="label">Address</label>
                                <v-input v-model="data.account.address"/>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-group">
                                <label class="label">Phone</label>
                                <v-input v-model="data.account.phone"/>
                            </div>
                        </div>
                    </div>

                    <div class="card__divider"></div>

                    <div class="row">
                        <div class="col-6">
                            <h3 class="card__title">Old credentials</h3>
                            <div class="form-group">
                                <label class="label">Email</label>
                                <v-input v-model="data.credentials.old.email"/>
                            </div>
                            <div class="form-group">
                                <label class="label">Email password</label>
                                <v-input v-model="data.credentials.old.email_password"/>
                            </div>
                            <div class="form-group">
                                <label class="label">Password</label>
                                <v-input v-model="data.credentials.old.password"/>
                            </div>
                            <div class="form-group">
                                <label class="label">Secret question</label>
                                <v-input v-model="data.credentials.old.secret_question"/>
                            </div>
                            <div class="form-group">
                                <label class="label">Secret question answer</label>
                                <v-input v-model="data.credentials.old.secret_question_answer"/>
                            </div>
                        </div>
                        <div class="col-6">
                            <h3 class="card__title">New credentials</h3>
                            <div class="form-group">
                                <label class="label">Email</label>
                                <v-input v-model="data.credentials.new.email"/>
                            </div>
                            <div class="form-group">
                                <label class="label">Email password</label>
                                <v-input v-model="data.credentials.new.email_password"/>
                            </div>
                            <div class="form-group">
                                <label class="label">Password</label>
                                <v-input v-model="data.credentials.new.password"/>
                            </div>
                            <div class="form-group">
                                <label class="label">Secret question</label>
                                <v-input v-model="data.credentials.new.secret_question"/>
                            </div>
                            <div class="form-group">
                                <label class="label">Secret question answer</label>
                                <v-input v-model="data.credentials.new.secret_question_answer"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-6">
                <div class="card card_grid">
                    <h3 class="card__title">Seller</h3>
                    <label class="label">Select existing</label>
                    <div class="form-select-btn">
                        <v-select :options="sellers" v-model="data.resource.seller_id"/>
                        <v-button @click="showModal">Add new</v-button>
                        <v-button @click="showModal" :disabled="!sellers.filter((seller) => seller.id === data.resource.seller_id)[0]">Edit</v-button>
                    </div>
                </div>

                <div class="card card_grid">
                    <h3 class="card__title">Login history</h3>
                    <div class="row">
                        <div class="col-4">
                            <div class="form-group">
                                <label class="label">Worker name</label>
                                <v-select :options="workers" v-model="loginHistory.name"/>
                            </div>
                        </div>
                        <div class="col-4">
                            <div class="form-group">
                                <label class="label">Computer</label>
                                <v-select :options="computers" v-model="loginHistory.computer"/>
                            </div>
                        </div>
                        <div class="col-4">
                            <div class="form-group">
                                <label class="label">VPN Software</label>
                                <v-select :options="vpn" v-model="loginHistory.vpn_software"/>
                            </div>
                        </div>
                        <div class="col-4">
                            <div class="form-group">
                                <label class="label">Country</label>
                                <v-select :options="countries" v-model="loginHistory.country"/>
                            </div>
                        </div>
                        <div class="col-4">
                            <div class="form-group">
                                <label class="label">VPN ID</label>
                                <v-input v-model="loginHistory.vpn_id"/>
                            </div>
                        </div>
                        <div class="col-4">
                            <div class="form-group">
                                <label class="label">Datetime</label>
                                <v-input type="datetime-local" v-model="loginHistory.datetime"/>
                            </div>
                        </div>
                        <v-button @click="addLogin" center>Add</v-button>
                    </div>

                    <div class="login-history" v-if="data.login_history.length">
                        <template v-for="(login, i) in data.login_history" :key="i">
                            <div class="card__divider"/>
                            <div class="login-history-item">
                                <div class="row">
                                    <div class="col-4">
                                        <span class="login-history-item__label">Worker name</span>
                                        <p class="login-history-item__value">{{ login.name }}</p>
                                    </div>
                                    <div class="col-4">
                                        <span class="login-history-item__label">Computer</span>
                                        <p class="login-history-item__value">{{ login.computer }}</p>
                                    </div>
                                    <div class="col-4">
                                        <span class="login-history-item__label">VPN Software</span>
                                        <p class="login-history-item__value">{{ login.vpn_software }}</p>
                                    </div>
                                    <div class="col-4">
                                        <span class="login-history-item__label">Country</span>
                                        <p class="login-history-item__value">{{ login.country }}</p>
                                    </div>
                                    <div class="col-4">
                                        <span class="login-history-item__label">VPN ID</span>
                                        <p class="login-history-item__value">{{ login.vpn_id }}</p>
                                    </div>
                                    <div class="col-4">
                                        <span class="login-history-item__label">Datetime</span>
                                        <p class="login-history-item__value">{{ login.datetime }}</p>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </div>

        <div class="card" v-if="data.operations">
            <h3 class="card__title">Related operations</h3>
            <div class="operation" v-for="operation in data.operations">
                <div class="card__divider"/>
                <h4 class="operation__title">{{ operation.initiator }} {{ operation.action }}</h4>
                <p class="operation__datetime">{{ operation.datetime }}</p>
            </div>
        </div>
    </div>
    <loader v-else/>
    <seller-modal
        v-if="modalVisible"
        :selectedSeller="sellers.filter((seller) => seller.id === data.resource.seller_id)[0]"
        @close-modal="modalVisible = false"
        @create-seller="createSeller"
        @edit-seller="editSeller"
    />
</template>

<script>
import { ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import { diff } from "@/diff/resource";

import { getAccountById, newAccount, editAccount } from "@/api/resource";
import { getSections } from "@/api/additional";
import { getSellers } from "@/api/seller";
import { getCountries } from "@/api/country";
import { getVPN } from "@/api/vpn";
import { getWorkers } from "@/api/worker";
import { getComputers } from "@/api/computer";

import Loader from "@/components/Loader";
import vInput from '@/components/UI/Input.vue';
import vTextarea from '@/components/UI/Textarea.vue';
import vSelect from '@/components/UI/Select.vue';
import vButton from '@/components/UI/Button.vue';
import vCheckbox from "@/components/UI/Checkbox";
import SellerModal from "@/components/Modals/SellerModal";

export default {
    name: "Resource",
    components: {
        Loader,
        vSelect,
        vInput,
        vTextarea,
        vButton,
        vCheckbox,
        SellerModal,
    },
    props: ['mode', 'type'],
    emits: ['close-modal', 'create-seller'],
    setup(props) {
        const router = useRouter();
        const route = useRoute();

        let id = route.params.id;
        let mode = props.mode;

        const data = ref({
            resource: {
                vendor: '',
                type: props.type,
                name: '',
                description: '',
                section_id: '',
                seller_id: '',
            },
            account: {
                battletag: '',
                addon_string: '',
                armory_link: '',
                safearmory_link: '',
                wallet_balance: '',
                basic_licence: '',
                email: '',
                email_password: '',
                cd_key: '',
                gift_code: '',
                name: '',
                surname: '',
                fake: false,
                country: '',
                address: '',
                phone: '',
            },
            credentials: {
                old: {
                    email: '',
                    email_password: '',
                    password: '',
                    secret_question: '',
                    secret_question_answer: '',
                },
                new: {
                    email: '',
                    email_password: '',
                    password: '',
                    secret_question: '',
                    secret_question_answer: '',
                },
            },
            login_history: [],
        });

        let timeout;
        const autosaved = ref(false);
        const autosavedTime = ref(null);

        const initWatcher = () => {
            // watch(
            //     () => data.value,
            //     () => {
            //         autosaved.value = false;
            //         if (timeout) clearTimeout(timeout);
            //
            //         timeout = setTimeout(() => {
            //             if (mode === 'new') {
            //                 newAccount(data.value).then((response) => {
            //                     mode = 'edit';
            //                     id = response.data.id;
            //                     autosavedTime.value = new Date().toLocaleString();
            //                     autosaved.value = true;
            //                 });
            //             } else {
            //                 editAccount(id, data.value).then(() => {
            //                     autosavedTime.value = new Date().toLocaleString();
            //                     autosaved.value = true;
            //                 });
            //             }
            //         }, 10000);
            //     },
            //     {
            //         deep: true,
            //     },
            // );
        }

        const datetimeToday = () => {
            const date = new Date();
            const year = date.getFullYear();
            const month = ((date.getMonth() + 1) < 10 ? '0' : '') + (date.getMonth() + 1);
            const day = (date.getDate() < 10 ? '0' : '') + date.getDate();
            const hours = date.getHours();
            const minutes = date.getMinutes();
            return `${year}-${month}-${day}T${hours}:${minutes}`;
        };

        const loginHistory = ref({
            name: '',
            computer: '',
            vpn_software: '',
            country: '',
            vpn_id: '',
            datetime: datetimeToday(),
        });

        const addLogin = () => {
            data.value.login_history.push(loginHistory.value);
            loginHistory.value = {
                name: '',
                computer: '',
                vpn_software: '',
                country: '',
                vpn_id: '',
                datetime: datetimeToday(),
            };
        };

        const loading = ref(true);

        const sections = ref([]);
        const sellers = ref([]);
        const countries = ref([]);
        const vpn = ref([]);
        const workers = ref([]);
        const computers = ref([]);

        const methods = [getSections(), getSellers()];
        if (mode === 'edit') {
            methods.push(getAccountById(id));
        }

        let original;

        Promise.all(methods).then((response) => {
            sections.value = response[0].data;
            sellers.value = response[1].data;

            if (mode === 'edit') {
                data.value = response[2].data;
                original = JSON.parse(JSON.stringify(response[2].data));
            }

            initWatcher();
            loading.value = false;
        });

        getCountries().then((response) => {
            countries.value = response.data;
        });
        getVPN().then((response) => {
            vpn.value = response.data;
        });
        getWorkers().then((response) => {
            workers.value = response.data;
        });
        getComputers().then((response) => {
            computers.value = response.data;
        });

        const modalVisible = ref(false);
        const showModal = () => {
            modalVisible.value = true;
        }

        const createSeller = (seller) => {
            sellers.value.push(seller);
            data.value.resource.seller_id = seller.id;
            modalVisible.value = false;
        }

        const editSeller = () => {
            modalVisible.value = false;
        }

        const submit = () => {
            if (mode === 'new') {
                newAccount(data.value).then(() => {
                    router.push('/resources/accounts');
                });
            } else {
                editAccount(id, data.value).then(() => {
                    router.push('/resources/accounts');
                });
            }
        }

        return {
            route,
            loading,
            data,
            autosaved,
            autosavedTime,
            sections,
            sellers,
            countries,
            vpn,
            workers,
            computers,
            loginHistory,
            addLogin,
            modalVisible,
            showModal,
            createSeller,
            editSeller,
            submit
        };
    },
}
</script>

<style lang="scss">
.section-header__autosave-message {
    margin-right: 10px;
}

.login-history-item {
    &:not(:last-of-type) {
        margin-bottom: 20px;
    }
}

.login-history-item__label {
    font-size: $t4;
    color: $type-light-dark;
    margin-bottom: 5px;
}

.login-history-item__value {
    margin-bottom: 10px;
}

.operation {
    &:not(:last-child) {
        margin-bottom: 20px;
    }

    &__title {
        margin-bottom: 10px;
    }

    &__datetime {
        font-size: $t3;
    }
}
</style>