export default {
    namespaced: true,
    state: {
        me: null,
        list: [],
    },
    mutations: {
        set: (state, payload) => {
            state.me = payload;
        },
        setList: (state, payload) => {
            state.list = { ...payload };
        },
    },
}