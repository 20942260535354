<template>
    <div class="checkbox" @click="$emit('update:modelValue', !modelValue)">
        <div class="checkbox__check" :class="{'checkbox__check_active': modelValue}"></div>
        <label class="checkbox__label">
            <slot/>
        </label>
    </div>
</template>

<script>
export default {
    name: "Checkbox",
    props: ['modelValue'],
    emits: ['update:modelValue'],
}
</script>

<style lang="scss">
    .checkbox {
        @extend %flex-aic;
        @extend %pointer-no-select;
    }

    .checkbox__check {
        height: 18px;
        width: 18px;
        border-radius: 2px;
        margin-right: 10px;
        border: 1px solid $button-dark-blue;
    }

    .checkbox__check_active {
        background-color: $button-dark-blue;
        background-image: url('../../assets/svg/check.svg');
        background-repeat: no-repeat;
        background-size: 14px 10px;
        background-position: center;
    }
</style>