<template>
    <div class="notification" :class="{'notification_error': type === 'Error'}" @click="link ? router.push(link) : null">
        <div class="notification__type">{{ type }}</div>
        <div class="notification__message">{{ message }}</div>
    </div>
</template>

<script>
import { onMounted } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

export default {
    name: "Notification",
    props: ['id', 'type', 'message', 'link'],
    setup(props) {
        const router = useRouter();
        const store = useStore();
        let timeoutId;

        onMounted(() => {
            timeoutId = setTimeout(() => {
                const id = props.id;
                hide(id);
            }, 3000);
        });

        const hide = (id) => {
            store.commit('notifications/delete', id);
        };

        return { router };
    },
}
</script>

<style lang="scss">
    .notification {
        @extend %pointer-no-select;
        background-color: $main-white;
        padding: 15px;
        border-radius: $card;
        width: 500px;
        height: 78px;
        box-shadow: 0px 13px 31px rgba(12, 20, 33, 0.04), 0px 9.45547px 20.8947px rgba(12, 20, 33, 0.032375), 0px 4.31641px 8.38574px rgba(12, 20, 33, 0.023125), 0px 2.6px 5.0375px rgba(12, 20, 33, 0.02), 0px 1.37109px 20px rgba(12, 20, 33, 0.2), inset 0px -2px 2px #F5F5F5;
        border: 1px solid $button-dark-blue;
        margin-bottom: 5px;
    }

    .notification_error {
        border-color: $accent-red;
    }

    .notification__type {
        font-size: $t3;
        margin-bottom: 5px;
    }

    .notification__message {
        font-size: $h4;
    }
</style>