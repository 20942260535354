<template>
    <teleport to="#notifications">
        <div class="notification-wrapper">
            <transition-group name="notifications">
                <notification
                    v-for="(notification, index) in notifications"
                    :key="index"
                    :id="notification.id"
                    :type="notification.type"
                    :message="notification.message"
                    :link="notification.link"
                />
            </transition-group>
        </div>
    </teleport>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";

import Notification from "../../components/Notifications/Notification";

export default {
    name: "NotificationsWrapper",
    components: {
        Notification,
    },
    setup() {
        const store = useStore();
        const notifications = computed(() => store.state.notifications.data);

        return { notifications };
    },
}
</script>

<style lang="scss">
    .notification-wrapper {
        display: flex;
        flex-direction: column-reverse;
        position: fixed;
        top: 75px;
        right: 55px;
        z-index: 9;
    }

    .notifications-enter-active,
    .notifications-leave-active {
        transition: all .4s ease;
    }

    .notifications-enter-from,
    .notifications-leave-to {
        opacity: 0;
        transition: all .4s ease;
        transform: translateX(500px);
    }

    .notifications-move {
        transition: transform .4s ease;
    }
</style>