<template>
    <modal>
        <template v-slot:title>New section</template>
        <template v-slot:content>
            <div class="row">
                <div class="col-xl-6">
                    <div class="form-group">
                        <label class="label">Name</label>
                        <v-input v-model="data.section.name"/>
                    </div>
                </div>
                <div class="col-xl-6">
                    <div class="form-group">
                        <label class="label">Alias</label>
                        <v-input v-model="data.section.alias"/>
                    </div>
                </div>
                <div class="col-xl-6">
                    <div class="form-group">
                        <label class="label">Game</label>
                        <v-select :options="games" v-model="data.section.game_id"/>
                    </div>
                </div>
                <div class="col-xl-6">
                    <div class="form-group">
                        <label class="label">Region</label>
                        <v-select :options="regions" v-model="data.section.region_id"/>
                    </div>
                </div>
                <div class="col-xl-6">
                    <div class="form-group">
                        <v-checkbox v-model="data.section.public">Public</v-checkbox>
                    </div>
                </div>
                <div class="col-xl-12">
                    <div class="section-features">
                        <div class="section-features__header">
                            <h4 class="section-features__title">Features</h4>
                            <a href="#" @click.prevent="addFeature">Add</a>
                        </div>

                        <div class="section-features__wrapper" v-if="data.features.length">
                            <draggable item-key="order" v-model="data.features" handle=".section-feature__drag" @change="updateOrder">
                                <template #item="{element, index}">
                                    <div class="section-feature">
                                        <div class="row">
                                            <div class="col-xl-5">
                                                <div class="form-group">
                                                    <label class="label">Key</label>
                                                    <v-input v-model="element.key"/>
                                                </div>
                                            </div>
                                            <div class="col-xl-5">
                                                <label class="label">Type</label>
                                                <v-select v-model="element.type" :options="[{id: 'string', name: 'String'}, {id: 'number', name: 'Number'}, {id: 'select', name: 'Select'}]"/>
                                            </div>
                                            <div class="col-xl-2 align-self-center">
                                                <img
                                                    src="@/assets/svg/move.svg"
                                                    alt=""
                                                    class="section-feature__drag"
                                                />
                                            </div>
                                            <div class="col-xl-2">
                                                <v-checkbox v-model="element.filter">Filter</v-checkbox>
                                            </div>
                                            <div class="col-xl-2">
                                                <v-checkbox v-model="element.public">Public</v-checkbox>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </draggable>
                        </div>
                    </div>
                </div>
                <v-button class="form-submit" center @click="submit" :disabled="loading">Submit</v-button>
            </div>
        </template>
    </modal>
</template>

<script>
import draggable from "vuedraggable";

import { ref } from "vue";
import { getGames, indexRegions, newSection, showSection, updateSection } from "../../api/additional";

import Modal from "./Modal";
import vInput from "../UI/Input";
import vSelect from "../UI/Select";
import vCheckbox from "../UI/Checkbox";
import vButton from "../UI/Button";

export default {
    name: "SectionModal",
    components: {
        draggable,
        Modal,
        vInput,
        vSelect,
        vCheckbox,
        vButton,
    },
    emits: ['close'],
    props: {
        id: {
            type: String,
        },
    },
    setup(props, context) {
        const data = ref({
            section: {
                name: '',
                alias: '',
                game_id: '',
                region_id: '',
                public: false,
            },
            features: [],
        });
        const edit = !!props.id;

        const regions = ref([]);
        indexRegions().then((response) => {
            regions.value = response.data;
        });

        const games = ref([]);
        getGames().then((response) => {
            games.value = response.data;
        });

        if (edit) {
            showSection(props.id).then((response) => {
                data.value = response.data;
            });
        }

        const addFeature = () => {
            data.value.features.push({
                id: null,
                key: '',
                type: null,
                filter: false,
                public: true,
                order: data.value.features.length + 1,
            });
        };

        const updateOrder = () => {
            data.value.features = data.value.features.map((feature, index) => {
               feature.order = index + 1;
               return feature;
            });
        }

        const loading = ref(false);
        const submit = () => {
            loading.value = true;

            if (edit) {
                updateSection(props.id, data.value).then(() => {
                    context.emit('close-modal');
                }).catch(() => {}).finally(() => {
                    loading.value = false;
                });
            } else {
                newSection(data.value).then(() => {
                    context.emit('close-modal');
                }).catch(() => {}).finally(() => {
                    loading.value = false;
                });
            }
        };

        return { data, regions, games, addFeature, updateOrder, loading, submit };
    },
};
</script>

<style lang="scss" scoped>
.section-features {
    padding: 10px;
    border: 1px solid $type-gray;

    &__header {
        @extend %flex-jcsb-aic;
    }

    &__wrapper {
        margin-top: 10px;
    }
}

.section-feature {
    &:not(:last-child) {
        margin-bottom: 15px;
    }

    &__drag {
        @extend %pointer-no-select;
        margin: auto;
    }

    &__remove {
        @extend %pointer-no-select;
    }
}
</style>