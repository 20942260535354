<template>
    <textarea class="textarea" :class="{'textarea_disabled': disabled}" :rows="rows" v-model="val"/>
</template>

<script>
import { computed } from "vue";

export default {
    name: "Textarea",
    emits: ['update:modelValue'],
    props: {
        modelValue: {
            type: String,
        },
        placeholder: {
            type: String,
        },
        rows: {
            type: String,
            default: '5',
        },
        disabled: {
            type: Boolean,
        },
    },
    setup(props, context) {
        const val = computed({
            get: () => props.modelValue,
            set: (newVal) => context.emit('update:modelValue', newVal),
        });

        return { val };
    },
}
</script>

<style lang="scss">
    .textarea {
        width: 100%;
        padding: 8px 10px;
        border: 1px solid $main-medium-gray;
        border-radius: $input;
        font-family: 'Inter', sans-serif;
        font-size: $t3;
        resize: vertical;
        min-height: 44px;
    }

    .textarea_disabled {
        pointer-events: none;
        user-select: none;
        background-color: $main-light-gray;
        color: $type-light-dark;
    }
</style>