<template>
    <header class="header">
        <a href="#" @click.prevent="signOut">Logout</a>

        <div class="header__admins">
            <span class="header__admins-label">Admins online:</span>
            <span v-for="(admin, index) in admins" :key="index" class="header__admins-item">
                <template v-if="admin">{{ admin.name }}</template>
            </span>
        </div>

        <div class="header__kamch-status" :class="{ 'header__kamch-status_disabled': !kamchReady }">
            Services status: {{ kamchReady ? 'active' : 'disabled' }}
        </div>
    </header>
</template>

<script>
import {computed} from "vue";
import {useStore} from "vuex";
import {useRouter} from "vue-router";

import api from "../api";

import {logout} from "../api/auth";

export default {
    name: "Header",
    setup(props, context) {
        const store = useStore();
        const router = useRouter();

        const signOut = () => {
            logout().then(() => {
                store.commit('user/set', null);
                localStorage.removeItem('user');
                localStorage.removeItem('token');
                api.defaults.headers.Authorization = null;
                context.emit('logout');
                router.push('/');
            });
        };

        const admins = computed(() => store.state.user.list);

        const kamchReady = store.state.kamchReady;

        return { signOut, admins, kamchReady };
    },
};
</script>

<style lang="scss" scoped>
.header {
    @extend %flex-aic;
    padding: 0 15px;
    height: 60px;
    background-color: $white;
    box-shadow: $block1;
}

.header__admins {
    @extend %flex-aic;
    margin-left: auto;
    margin-right: 40px;
}

.header__admins-label {
    margin-right: 10px;
}

.header__admins-item {
    background-color: $button-dark-blue;
    color: $type-white;
    font-size: $t4;
    padding: 5px;
    border-radius: 4px;
    margin-right: 5px;
}

.header__buttons {
    display: flex;
}

.header__button {
    @extend %pointer-no-select;

    &:not(:last-child) {
        margin-right: 30px;
    }
}

.header__kamch-status {
    &_disabled {
        color: $accent-red;
    }
}
</style>