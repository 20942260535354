<template>
    <div class="section">
        <div class="section-header card">
            <div class="section-header__title">Admins</div>
            <div class="section-header__count">{{ computedAdmins.length }} admins</div>
        </div>

        <div class="row">
            <div class="col-xl-6">
                <div class="card card_no-padding">
                    <v-table :headers="headers" :items="computedAdmins" :loading="adminsLoading"/>
                </div>
            </div>
            <div class="col-xl-6">
                <div class="card card_sticky">
                    <h3 class="card__title">New admin</h3>
                    <div class="row">
                        <div class="col-xl-6">
                            <div class="form-group">
                                <label class="label">Name</label>
                                <v-input v-model="admin.name"/>
                            </div>
                        </div>
                        <div class="col-xl-6">
                            <div class="form-group">
                                <label class="label">Email</label>
                                <v-input v-model="admin.email"/>
                            </div>
                        </div>
                        <div class="col-xl-6">
                            <div class="form-group">
                                <label class="label">Password</label>
                                <v-input type="password" v-model="admin.password"/>
                            </div>
                        </div>
                        <div class="col-xl-6">
                            <div class="form-group">
                                <label class="label">Repeat</label>
                                <v-input type="password" v-model="repeatedPassword"/>
                            </div>
                        </div>
                        <v-button center @click="submit">Submit</v-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { computed, ref } from "vue";
import { useStore } from "vuex";

import { getAdmins, newAdmin } from "@/api/admins";

import vTable from "@/components/UI/Table.vue";
import vInput from "@/components/UI/Input.vue";
import vButton from "@/components/UI/Button.vue";

export default {
    name: "Admins",
    components: {
        vTable,
        vInput,
        vButton,
    },
    setup() {
        const store = useStore();
        const headers = [
            {
                type: 'standard',
                label: 'Name',
                sorting: true,
                filter: true,
                field: 'name',
            },
            {
                type: 'standard',
                label: 'Email',
                sorting: true,
                filter: true,
                field: 'email',
            },
            {
                type: 'standard',
                label: 'Online',
                field: 'online',
            },
            {
                type: 'timestamp',
                label: 'Last login',
                sorting: false,
                filter: false,
                field: 'last_online_at',
            },
        ];

        const adminsLoading = ref(true);
        const admins = ref([]);
        getAdmins().then((response) => {
            admins.value = response.data;
            adminsLoading.value = false;
        });

        const computedAdmins = computed(() => {
            return admins.value.map((admin) => {
                admin['online'] = !!store.state.user.list[admin['id']];
                return admin;
            });
        });

        const admin = ref({
            name: '',
            email: '',
            password: '',
        });
        const repeatedPassword = ref('');

        const submit = () => {
            if (admin.value.password !== repeatedPassword.value) {
                store.commit('notifications/new', {
                    id: 1,
                    type: 'Error',
                    message: `Passwords mismatch`,
                });
            } else {
                newAdmin(admin.value).then((response) => {
                     admins.value.push(response.data);
                     admin.value = {
                         name: '',
                         email: '',
                         password: '',
                     };
                    repeatedPassword.value = '';
                });
            }
        }

        return { headers, adminsLoading, computedAdmins, admin, repeatedPassword, submit };
    }
}
</script>

<style>

</style>