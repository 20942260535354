<template>
    <modal>
        <template v-slot:title>Task results</template>
        <template v-slot:content>
            <table class="table">
                <thead class="table__header">
                <tr>
                    <td class="table__header-item">ID</td>
                    <td class="table__header-item">Action</td>
                    <td class="table__header-item">Post ID</td>
                </tr>
                </thead>
                <tbody>
                    <tr class="table__row" v-for="taskResult in taskResults" :key="taskResult.id">
                        <td>{{ taskResult.id }}</td>
                        <td>{{ taskResult.action }}</td>
                        <td>{{ taskResult.post.id }}</td>
                    </tr>
                </tbody>
            </table>
        </template>
    </modal>
</template>

<script>
import { ref } from 'vue';

import { getTaskResults } from "../../api/kamch";

import Modal from "./Modal";

export default {
    components: {
        Modal,
    },
    props: {
        id: {
            type: Number,
        },
    },
    setup(props) {
        const taskResults = ref([]);
        getTaskResults(props.id).then((response) => {
           taskResults.value = response.data;
        });

        return { taskResults };
    },
};
</script>