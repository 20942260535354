<template>
    <div class="draft" v-if="availableDraft">
        <p class="draft__label">Draft available</p>
        <a href="#" @click.prevent="applyDraft" class="draft__link">Apply</a>
        <a href="#" @click.prevent="deleteDraft" class="draft__link">Delete</a>
    </div>
</template>

<script>
import { computed, watch } from "vue";
import { useStore } from "vuex";

import { storeDraft, destroyDraft } from "../api/draft";

export default {
    name: "Draft",
    props: {
        alias: {
            type: String,
            required: true,
        },
        data: {
            type: Object,
            required: true,
        },
    },
    emits: ['apply'],
    setup(props, {emit}) {
        const store = useStore();

        let timeout = null;
        watch(
            () => props.data,
            (val) => {
                if (timeout) clearTimeout(timeout);

                timeout = setTimeout(() => {
                    const draft = {
                        target: props.alias,
                        content: JSON.stringify(val),
                    };
                    storeDraft(draft).then(() => {});
                }, 500);
            },
            {
                deep: true,
            },
        );

        const availableDraft = computed(() => store.state.drafts.data.find((item) => item.target === props.alias));

        const applyDraft = () => {
            if (availableDraft) {
                const id = availableDraft.value.id;
                const content = JSON.parse(availableDraft.value.content);
                store.commit('drafts/unset', id);
                emit('apply', content);
            }
        };

        const deleteDraft = () => {
            const id = availableDraft.value.id;
            destroyDraft(id).then(() => {
                store.commit('drafts/unset', id);
            });
        };

        return {
            availableDraft,
            applyDraft,
            deleteDraft,
        };
    },
};
</script>

<style lang="scss" scoped>
.draft {
    @extend %flex-aic;
    position: fixed;
    right: 20px;
    bottom: 20px;
    box-shadow: $block2;
    background-color: $main-white;
    border-radius: 8px;
    padding: 15px;

    &__label {
        margin-right: 30px;
    }

    &__link {
        &:not(:last-child) {
            margin-right: 10px;
        }
    }
}
</style>
