const axios = require('axios').default;

import store from "@/store";

axios.defaults.withCredentials = true;
axios.defaults.baseURL = `${process.env.VUE_APP_API_BASE}/api`;
axios.defaults.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;

// axios.interceptors.response.use(function (response) {
//     return response;
// }, function (error) {
//     const status = error.response.status;
//     switch (status) {
//         case 404:
//             store.commit('errors/set', {
//                 active: true,
//                 code: 404,
//                 message: 'Not Found',
//             });
//             break;
//         case 401:
//             store.commit('user/set', null);
//             localStorage.removeItem('user');
//             localStorage.removeItem('token');
//             axios.defaults.headers.Authorization = null;
//             break;
//         case 500:
//             break;
//     }
//
//     return error;
// });

export default axios;