const axios = require('axios').default;
const apiBase = process.env.NODE_ENV === 'production' ? 'https://admin.safepoint.cc/api2' : 'http://localhost:8000/api';

console.log(apiBase);

export const healthCheck = () => {
    return axios.get(`${apiBase}/healthcheck`, { timeout: 2000 });
};

export const resourcesCheck = () => {
    return axios.get(`${apiBase}/resources/status`);
}

export const getTasks = (product_id) => {
    return axios.get(`${apiBase}/tasks/product/${product_id}`);
};

export const getTaskResults = (task_id) => {
    return axios.get(`${apiBase}/results/tasks/${task_id}`);
}

export const getActivePosts = (product_id) => {
    return axios.get(`${apiBase}/posts/products/${product_id}?active=true`);
}

export const repeatTask = (task_id) => {
    return axios.post(`${apiBase}/tasks/${task_id}/repeat`);
}

export const getChecklists = (product_id) => {
    return axios.get(`${apiBase}/checklists/${product_id}`);
}

export const getWowAccountData = (product_id) => {
    return axios.get(`${apiBase}/product_data/accounts/wow/${product_id}`);
}

export const getOriginalImage = (product_id) => {
    return axios.get(`${apiBase}/images/product/${product_id}`);
}

export const uploadOriginalImage = (data, product_id) => {
    return axios.post(`${apiBase}/images/product/${product_id}`, data);
}
