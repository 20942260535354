<template>
    <div class="section" v-if="!loading">
        <div class="section-header card">
            <h2 class="section-header__title">{{ route.meta.title }}</h2>
        </div>

        <div class="card">
            <h3 class="card__title">Main info</h3>
            <p class="card__info"><b>Number:</b> {{ contactUsRequest.number }}</p>
            <p class="card__info"><b>Email:</b> {{ contactUsRequest.email }}</p>
            <p class="card__info"><b>Subject:</b> {{ contactUsRequest.subject }}</p>
            <p class="card__info"><b>Message:</b> {{ contactUsRequest.message }}</p>
            <p class="card__info" v-if="contactUsRequest.image"><b>Image:</b> <img :src="contactUsRequest.image" alt=""></p>
        </div>
    </div>
    <loader v-else/>
</template>

<script>
import { ref } from "vue";
import { useRoute } from "vue-router";
import { getContactUsRequestById } from "../api/contact-us-requests";
import { timestamp } from "../filters";

import Loader from "../components/Loader";

export default {
    name: "ContactUsRequest",
    components: {
        Loader,
    },
    setup() {
        const route = useRoute();
        const id = route.params.id;

        const loading = ref(true);
        const contactUsRequest = ref({});

        getContactUsRequestById(id).then((response) => {
            contactUsRequest.value = response.data;
            loading.value = false;
        });

        return { loading, route, contactUsRequest, timestamp };
    },
}
</script>