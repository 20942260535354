<template>
    <div class="section">
        <div class="section-header card">
            <h2 class="section-header__title">Products</h2>
            <div class="list-buttons">
                <router-link to="/products/new">
                    <v-button>Add new</v-button>
                </router-link>
            </div>
        </div>

        <div class="section-filters card" v-if="false">
            <div class="section-filters__item">
                <label class="label">Title</label>
                <v-input v-model="filters.title"/>
            </div>
        </div>

        <products-filter/>

        <div class="card card_no-padding">
            <v-table-advanced>
                <template v-slot:head>
                    <th>Vendor</th>
                    <th>Title</th>
                    <th>Alias</th>
                    <th>Game</th>
                    <th>Region</th>
                    <th>Section</th>
                    <th>Status</th>
                    <th>Public</th>
                    <th>Created</th>
                    <th>Updated</th>
                </template>
                <template v-slot:body>
                    <router-link :to="`/products/${product.id}`" v-for="product in products" :key="product.id">
                        <td>{{ product.vendor }}</td>
                        <td>{{ product.title }}</td>
                        <td>{{ product.alias }}</td>
                        <td>{{ product.game.name }}</td>
                        <td>{{ product.region.name }}</td>
                        <td>{{ product.section.name }}</td>
                        <td>{{ product.status }}</td>
                        <td>{{ product.public ? 'Yes' : 'No' }}</td>
                        <td>{{ timestamp(product.created_at) }}</td>
                        <td>{{ timestamp(product.updated_at) }}</td>
                    </router-link>
                </template>
            </v-table-advanced>
        </div>

        <div class="card">
            <pagination :pagination="pagination"/>
        </div>
    </div>
</template>

<script>
import { ref } from "vue";
import { useRoute, useRouter } from 'vue-router';

import { indexProducts } from "../api/product";
import { getGames, indexRegions, getSections } from "../api/additional";

import { timestamp } from '../filters';
import { filterObjectByFalsy } from "../utils";

import vInput from "../components/UI/Input";
import vSelect from "../components/UI/Select";
import vButton from "../components/UI/Button";
import vTable from "../components/UI/Table";
import vTableAdvanced from "../components/UI/TableAdvanced";
import Pagination from "../components/UI/Pagination";
import ProductsFilter from "../components/ProductsFilter";

export default {
    name: "Products",
    components: {
        vInput,
        vSelect,
        vButton,
        vTable,
        vTableAdvanced,
        Pagination,
        ProductsFilter,
    },
    setup() {
        const route = useRoute();
        const router = useRouter();
        const loading = ref(true);
        const headers = [
            {
                type: 'standard',
                label: 'Vendor',
                field: 'vendor',
            },
            {
                type: 'standard',
                label: 'Name',
                field: 'title',
            },
            {
                type: 'standard',
                label: 'Alias',
                field: 'alias',
            },
            {
                type: 'nested',
                label: 'Game',
                field: 'game.name',
            },
            {
                type: 'nested',
                label: 'Region',
                field: 'region.name',
            },
            {
                type: 'nested',
                label: 'Section',
                field: 'section.name',
            },
            {
                type: 'standard',
                label: 'Status',
                field: 'status',
            },
            {
                type: 'timestamp',
                label: 'Created',
                field: 'created_at',
            },
            {
                type: 'timestamp',
                label: 'Updated',
                field: 'updated_at',
            },
        ];

        const games = ref([]);
        const regions = ref([]);
        const sections = ref([]);

        Promise.all([getGames(), indexRegions(), getSections()]).then((response) => {
            games.value = response[0].data;
            regions.value = response[1].data;
            sections.value = response[2].data;
        });

        const products = ref([]);
        const pagination = ref({});

        const filters = filterObjectByFalsy({
            vendor: route.query.vendor || '',
            title: route.query.title || '',
            alias: route.query.alias || '',
            game_id: route.query.game_id || '',
        });
        const page = route.query.page || 1;

        indexProducts(page, filters).then((response) => {
            products.value = response.data.products;
            pagination.value = response.data.pagination;
            loading.value = false;
        });

        return {
            router,
            loading,
            headers,
            games,
            regions,
            sections,
            products,
            pagination,
            timestamp
        };
    },
}
</script>