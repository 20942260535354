<template>
    <template v-if="!images.length">
        <span v-if="loading">Loading..</span>
        <input type="file" name="myImage" accept="image/x-png,image/gif,image/jpeg" @change="change($event)" :multiple="multiple" v-else/>
    </template>
    <template v-else>
        <div class="row">
            <div class="col-3" v-for="(image, index) of images" :key="index">
                <img :src="`${image}`" alt="">
                <a href="#" @click.prevent="del(image)">Remove</a>
            </div>
        </div>
        <template v-if="multiple">
            <label class="label">Load more:</label>
            <input type="file" name="myImage" accept="image/x-png,image/gif,image/jpeg" @change="change($event)" :multiple="multiple"/>
        </template>
    </template>
</template>

<script>
import { computed, ref } from "vue";

import { upload, remove } from "../../api/files";
import { uploadOriginalImage } from "../../api/kamch";

export default {
    name: "ImageUploader",
    emits: ['update:modelValue'],
    props: {
        modelValue: {
            type: [Object, String],
        },
        folder: {
            type: String,
            default: 'etc',
        },
        multiple: {
            type: Boolean,
            default: false,
        },
        id: {
            type: String,
        },
    },
    setup(props, context) {
        const loading = ref(false);

        const images = computed(() => {
            const imageArray = [];
            return props.modelValue ? imageArray.concat(props.modelValue) : [];
        });

        const change = (event) => {
            loading.value = true;
            const files = event.target.files;
            const formData = new FormData();

            formData.append('image', files[0]);

            if (props.folder === 'products') {
                uploadOriginalImage(formData, props.id).then(() => {});
            }

            upload(formData, props.folder).then((response) => {
                const images = response.data;
                context.emit('update:modelValue', props.multiple ? images[0] : images);
                loading.value = false;
            });
        }

        const del = (path) => {
            remove(path.replace('https://safe-armory.ams3.digitaloceanspaces.com/', '')).then(() => {
                context.emit('update:modelValue', props.multiple ? [] : '');
            });
        }

        return { loading, images, change, del };
    },
}
</script>