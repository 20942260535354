<template>
    <modal>
        <template v-slot:title>New game</template>
        <template v-slot:content>
            <div class="row">
                <div class="col-xl-6">
                    <div class="form-group">
                        <label class="label">Name</label>
                        <v-input v-model="data.game.name"/>
                    </div>
                </div>
                <div class="col-xl-6">
                    <div class="form-group">
                        <label class="label">Alias</label>
                        <v-input v-model="data.game.alias"/>
                    </div>
                </div>
                <div class="col-xl-6">
                    <div class="form-group">
                        <label class="label">Image</label>
                        <image-uploader v-model="data.game.image"/>
                    </div>
                </div>
                <div class="col-xl-6">
                    <div class="form-group">
                        <label class="label">Background mage</label>
                        <image-uploader v-model="data.game.background_image"/>
                    </div>
                </div>
                <div class="col-xl-6">
                    <div class="form-group">
                        <v-checkbox v-model="data.game.public">Public</v-checkbox>
                    </div>
                </div>
                <div class="col-xl-12">
                    <div class="game-regions">
                        <div class="game-regions__header">
                            <h4 class="section-features__title">Regions</h4>
                            <a href="#" @click.prevent="addRegion">Add</a>
                        </div>
                        <div class="game-regions__wrapper" v-if="data.regions.length">
                            <div class="game-region" v-for="(region, index) in data.regions" :key="index">
                                <div class="row">
                                    <div class="col-xl-9">
                                        <v-select :options="regions" v-model="data.regions[index].region_id"/>
                                    </div>
                                    <div class="col-xl-2 align-self-center">
                                        <v-checkbox v-model="data.regions[index].public">Public</v-checkbox>
                                    </div>
                                    <div class="col-xl-1 align-self-center">
                                        <img
                                            src="@/assets/svg/modal-close.svg"
                                            alt=""
                                            class="section-feature__remove"
                                            @click="data.regions.splice(index, 1)"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <v-button class="form-submit" center @click="submit" :disabled="loading">Submit</v-button>
            </div>
        </template>
    </modal>
</template>

<script>
import { ref } from "vue";
import { indexRegions, storeGame, updateGame, showGame } from "../../api/additional";

import Modal from "./Modal";
import vInput from "../UI/Input";
import vSelect from "../UI/Select";
import vCheckbox from "../UI/Checkbox";
import vButton from "../UI/Button";
import ImageUploader from "../UI/ImageUploader";

export default {
    name: "GameModal",
    components: {
        ImageUploader,
        Modal,
        vInput,
        vSelect,
        vCheckbox,
        vButton,
    },
    emits: ['close'],
    props: {
        id: {
            type: String,
        },
    },
    setup(props, context) {
        const data = ref({
            game: {
                name: '',
                alias: '',
                image: '',
                background_image: '',
                public: false,
            },
            regions: [],
        });
        const edit = !!props.id;

        if (edit) {
            showGame(props.id).then((response) => {
                data.value = response.data;
            });
        }

        const regions = ref([]);
        indexRegions().then((response) => {
            regions.value = response.data;
        });

        const addRegion = () => {
            data.value.regions.push({
                region_id: '',
                public: false,
            });
        };

        const loading = ref(false);
        const submit = () => {
            loading.value = true;
            if (edit) {
                updateGame(props.id, data.value).then(() => {
                    context.emit('close-modal');
                }).catch(() => {}).finally(() => {
                    loading.value = false;
                });
            } else {
                storeGame(data.value).then(() => {
                    context.emit('close-modal');
                }).catch(() => {}).finally(() => {
                    loading.value = false;
                });
            }
        };

        return { data, regions, addRegion, loading, submit };
    },
};
</script>

<style lang="scss" scoped>
.game-regions {
    padding: 10px;
    border: 1px solid $type-gray;

    &__header {
        @extend %flex-jcsb-aic;
    }

    &__wrapper {
        margin-top: 10px;
    }
}

.game-region {
    &:not(:last-child) {
        margin-bottom: 15px;
    }

    &__remove {
        @extend %pointer-no-select;
    }
}
</style>