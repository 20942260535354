<template>
    <button class="btn" :class="{'btn_center': center, 'btn_disabled': disabled, 'btn_outline': outline}">
        <slot/>
        <img :src="require(`@/assets/svg/button-${icon}.svg`)" alt="" class="btn__icon" v-if="icon">
    </button>
</template>

<script>
export default {
    name: "Button",
    props: {
        type: {
            type: String,
            default: 'button',
        },
        center: {
            type: Boolean,
        },
        disabled: {
            type: Boolean,
        },
        outline: {
            type: Boolean,
        },
        icon: {
            type: String,
        },
    },
};
</script>

<style lang="scss">
    .btn {
        @extend %pointer-no-select;
        font: $b1 'Inter', san-serif;
        padding: 7px 10px;
        color: $type-white;
        background-color: $button-dark-blue;
        border-radius: $button;
        letter-spacing: 0.75px;
        border: 1px solid $button-dark-blue;
        transition: background-color .2s ease, color .2s ease;
        &:hover {
            background-color: transparent;
            color: $button-dark-blue;
        }
    }

    .btn_outline {
        background-color: transparent;
        color: $button-dark-blue;
        &:hover {
            background-color: $button-dark-blue;
            color: $type-white;
        }
    }

    .btn_center {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    .btn_disabled {
        background-color: $button-light-gray;
        border-color: $button-light-gray;
        pointer-events: none;
    }

    .btn__icon {
        display: inline;
        margin-left: 10px;
    }
</style>