<template>
    <div class="select-wrapper" :class="{ 'select_opened': opened }" tabindex="1" @focusout="opened = false">
        <div class="select__input" @click="opened = !opened">
            {{ selectedLabel }}
        </div>
        <img src="@/assets/svg/select-arrow.svg" alt="" class="select__arrow">
        <transition name="fade">
            <div class="select__options" v-if="opened">
                <div class="select__option" v-for="option in options" :key="option[value]" @click="select(option[value])">{{ option[label] }}</div>
                <div class="select__empty" v-if="!options.length">Empty</div>
            </div>
        </transition>
    </div>
</template>

<script>
import { ref, computed } from 'vue';

import vInput from '@/components/UI/Input.vue';

export default {
    name: "Select",
    emits: ['update:modelValue'],
    components: {
        vInput,
    },
    props: {
        modelValue: {
            type: String,
        },
        options: {
            type: Array,
            required: true,
        },
        value: {
            type: String,
            default: 'id',
        },
        label: {
            type: String,
            default: 'name',
        },
    },
    setup(props, context) {
        const opened = ref(false);
        const selected = ref({});

        const selectedLabel = computed(() => {
            if (props.options.length) {
                const filter = props.options.filter((item) => item[props.value] === props.modelValue);
                if (filter.length) {
                    return filter[0][props.label];
                } else {
                    return null;
                }
            } else {
                return null;
            }
        });

        const select = (selectedValue) => {
            selected.value = selectedValue;
            opened.value = false;
            context.emit('update:modelValue', selectedValue);
        }

        return { opened, selected, select, selectedLabel };
    },
}
</script>

<style lang="scss">
.select-wrapper {
    position: relative;
}

.select_opened {
    .select__arrow {
        transform: rotate(180deg);
    }
}

.select__input {
    width: 100%;
    min-width: 221px;
    padding: 8px 10px;
    border: 1px solid $main-medium-gray;
    border-radius: $input;
    font-family: 'Inter', sans-serif;
    font-size: $t3;
    height: 35px;
    user-select: none;
}

.select__arrow {
    position: absolute;
    top: 13.5px;
    right: 10px;
    transform-origin: center;
    transition: transform .4s ease;
}

.select__options {
    position: absolute;
    top: 100%;
    width: 100%;
    z-index: 2;
    max-height: 340px;
    overflow-y: auto;
}

.select__option {
    @extend %pointer-no-select;
    font-size: $t3;
    padding: 8px 10px;
    border: 1px solid $main-medium-gray;
    margin-top: -1px;
    background-color: $type-white;
    &:hover {
        background-color: $type-light-gray;
    }
}

.select__empty {
    @extend %pointer-no-select;
    font-size: $t3;
    padding: 8px 10px;
    border: 1px solid $main-medium-gray;
    text-align: center;
    margin-top: -1px;
    background-color: $type-white;
}
</style>