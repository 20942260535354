<template>
    <table>
        <thead>
            <slot name="head"/>
        </thead>
        <tbody v-if="!loading">
            <slot name="body"/>
        </tbody>
    </table>
</template>

<script>
export default {
    name: "TableAdvanced",
    props: {
        loading: {
            type: 'Boolean',
        },
    },
};
</script>

<style lang="scss" scoped>
table {
    width: 100%;
    border-collapse: collapse;
}

thead {
    height: 60px;
    border-bottom: 1px solid $type-accent;

    ::v-deep(th) {
        padding: 15px;
        color: $type-accent;
        font-weight: normal;
        text-align: left;
    }
}

::v-deep(tr), ::v-deep(a) {
    display: table-row;
    height: 60px;
    cursor: pointer;
    font-size: $t3;

    &:not(:last-child) {
        border-bottom: 1px solid $type-light;
    }

    &:hover {
        background-color: rgba($type-accent, .04);
    }

    td {
        color: $type-dark;
        padding: 15px;
        vertical-align: middle;
    }
}
</style>