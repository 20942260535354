import api from '@/api';

export const get = (path) => {
    return api.get(`/files?path=${path}`);
}

export const upload = (file, folder) => {
    return api.post(`/upload/${folder}`, file, {
        headers: {
            'Content-Type': 'multipart/form-data'
        },
    });
}

export const remove = (path) => {
    return api.delete(`/files?path=${path}`);
}