import api from "../api";

export const indexPromocodes = () => {
    return api.get('/promocodes');
}

export const showPromocode = (id) => {
    return api.get(`/promocodes/${id}`);
}

export const storePromocode = (data) => {
    return api.post('/promocodes', data);
}

export const updatePromocode = (id, data) => {
    return api.patch(`/promocodes/${id}`, data);
}

export const destroyPromocode = (id) => {
    return api.delete(`/promocodes/${id}`);
}