<template>
    <modal>
        <template v-slot:title>New seller</template>
        <template v-slot:content>
            <div class="row">
                <div class="col-6">
                    <div class="form-group">
                        <label class="label">Name</label>
                        <v-input v-model="seller.name"/>
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group">
                        <label class="label">Surname</label>
                        <v-input v-model="seller.surname"/>
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group">
                        <label class="label">Patronymic (optional)</label>
                        <v-input v-model="seller.patronymic"/>
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group">
                        <label class="label">Country</label>
                        <v-input v-model="seller.country"/>
                    </div>
                </div>
            </div>

            <div class="modal__divider"/>
            <p class="modal__subtitle">Passport</p>

            <div class="row">
                <div class="col-6">
                    <div class="form-group">
                        <label class="label">Series and №</label>
                        <v-input v-model="seller.psan"/>
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group">
                        <label class="label">Issued by</label>
                        <v-input v-model="seller.ib"/>
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group">
                        <label class="label">Date of Issue</label>
                        <v-input type="date" v-model="seller.doi"/>
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group">
                        <label class="label">Department Code</label>
                        <v-input v-model="seller.dc"/>
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group">
                        <label class="label">Date of Birth</label>
                        <v-input type="date" v-model="seller.dob"/>
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group">
                        <label class="label">Place of Birth</label>
                        <v-input v-model="seller.pob"/>
                    </div>
                </div>
            </div>

            <div class="modal__divider"/>

            <div class="modal__subtitle">Address</div>
            <div class="row row-form">
                <div class="col-12">
                    <div class="form-group">
                        <label class="label">Registered</label>
                        <v-input v-model="seller.address_registered"/>
                    </div>
                </div>
                <div class="col-12">
                    <div class="form-group">
                        <label class="label">Current</label>
                        <v-input v-model="seller.address_current"/>
                    </div>
                </div>
            </div>
            <v-button class="form-submit" center @click="submit">Submit</v-button>
        </template>
    </modal>
</template>

<script>
import { ref } from "vue";

import { newSeller, editSeller } from "@/api/seller";

import Modal from "@/components/Modals/Modal";

import vInput from "@/components/UI/Input";
import vButton from "@/components/UI/Button";

export default {
    name: "SellerModal",
    components: {
        Modal,
        vInput,
        vButton,
    },
    props: ['selectedSeller'],
    emits: ['close'],
    setup(props, context) {
        const edit = !!props.selectedSeller;

        const seller = ref({
            name: '',
            surname: '',
            patronymic: '',
            country: '',
            psan: '',
            ib: '',
            doi: '',
            dc: '',
            dob: '',
            pob: '',
            address_registered: '',
            address_current: '',
        });

        if (edit) {
            seller.value = props.selectedSeller;
        }

        const submit = () => {
            if (!edit) {
                newSeller(seller.value).then((response) => {
                    context.emit('create-seller', response.data);
                });
            } else {
                editSeller(seller.value.id, seller.value).then(() => {
                    context.emit('edit-seller');
                });
            }
        }

        return {
            seller,
            submit,
        };
    },
}
</script>

<style>

</style>