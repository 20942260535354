<template>
    <div class="section" v-if="!loading">
        <div class="section-header card">
            <h2 class="section-header__title">{{ route.meta.title }} #{{ sellRequest.number }}</h2>
        </div>

        <div class="row">
            <div class="col-xl-6">
                <div class="card">
                    <h3 class="card__title">Main info</h3>

                    <p class="card__info"><b>Name:</b> {{ sellRequest.name }}</p>
                    <p class="card__info"><b>Armory link:</b> {{ sellRequest.armory_link }}</p>
                    <p class="card__info"><b>Unobtainable items:</b> {{ sellRequest.items }}</p>
                    <p class="card__info"><b>Gold available:</b> {{ sellRequest.gold }}</p>
                    <p class="card__info"><b>Notable titles and mounts:</b> {{ sellRequest.titles_and_mounts }}</p>
                    <p class="card__info"><b>Description:</b> {{ sellRequest.description }}</p>
                    <p class="card__info"><b>Created:</b> {{ timestamp(sellRequest.created_at) }}</p>
                </div>

                <div class="card" v-if="sellRequest.user">
                    <h3 class="card__title">User</h3>
                    <p class="card__info"><b>Nickname:</b> {{ sellRequest.user.nickname }}</p>
                    <p class="card__info"><b>Email:</b> {{ sellRequest.user.email }}</p>
                </div>
            </div>
            <div class="col-xl-6">
                <div class="card">
                    <h3 class="card__title">Contacts</h3>

                    <p class="card__info" v-for="contact in sellRequest.contacts" :key="contact.id">
                        <span style="text-transform: capitalize; font-weight: bold">{{ contact.type }}:</span>
                        {{ contact.value }}
                        {{ contact.default ? '(default)' : '' }}
                    </p>
                </div>
            </div>
        </div>
    </div>
    <loader v-else/>
</template>

<script>
import { ref } from "vue";
import { useRoute } from "vue-router";

import { timestamp } from "@/filters";
import { getSellRequestById } from "../api/sell-requests";

import Loader from "../components/Loader";

export default {
    name: "SellRequest",
    components: {
        Loader,
    },
    setup() {
        const route = useRoute();
        const id = route.params.id;

        const loading = ref(true);
        const sellRequest = ref([]);

        getSellRequestById(id).then((response) => {
            sellRequest.value = response.data;
            loading.value = false;
        });

        return { loading, route, sellRequest, timestamp };
    },
}
</script>

<style scoped>

</style>