<template>
    <div class="section">
        <div class="section-header card">
            <div class="section-header__title">Promocodes</div>
            <div class="section-header__count">{{ promocodes.length }} promocodes</div>
            <div class="list-buttons">
                <v-button @click="editId = null; promocodeModalVisible = true">Add new</v-button>
            </div>
        </div>
        <div class="card card_no-padding">
            <v-table
                :headers="headers"
                :items="promocodes"
                :loading="promocodesLoading"
                @edit="showEditModal"
                @delete="destroyPromocode"
            />
        </div>
        <promocode-modal
            v-if="promocodeModalVisible"
            :id="editId"
            @close-modal="promocodeModalVisible = false"
        />
    </div>
</template>

<script>
import { ref } from 'vue';

import { indexPromocodes, destroyPromocode } from "../api/promocodes";

import vButton from '../components/UI/Button.vue';
import vTable from '../components/UI/Table.vue';
import PromocodeModal from "../components/Modals/PromocodeModal";

export default {
    name: "Promocodes",
    components: {
        vButton,
        vTable,
        PromocodeModal,
    },
    setup() {
        const headers = [
            {
                type: 'standard',
                label: 'Code',
                sorting: true,
                filter: true,
                field: 'code',
            },
            {
                type: 'standard',
                label: 'Sale (%)',
                sorting: true,
                filter: true,
                field: 'sale',
            },
            {
                type: 'timestamp',
                label: 'Created at',
                sorting: true,
                filter: true,
                field: 'created_at',
            },
            {
                type: 'timestamp',
                label: 'Updated at',
                sorting: true,
                filter: true,
                field: 'updated_at',
            },
            {
                type: 'boolean',
                label: 'Activated',
                sorting: true,
                filter: true,
                field: 'activated',
            },
            {
                type: 'actions',
                label: null,
                sorting: false,
                filter: false,
                field: null,
            },
        ];

        const promocodes = ref([]);
        const promocodesLoading = ref(true);
        indexPromocodes().then((response) => {
            promocodes.value = response.data;
            promocodesLoading.value = false;
        });
        const promocodeModalVisible = ref(false);

        const editId = ref(null);
        const showEditModal = (id) => {
            editId.value = id;
            promocodeModalVisible.value = true;
        }

        return {
            headers,
            promocodes,
            promocodesLoading,
            promocodeModalVisible,
            editId,
            showEditModal,
            destroyPromocode,
        };
    },
};
</script>

<style scoped>

</style>