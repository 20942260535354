import api from "@/api";

export const getSellers = () => {
    return api.get('/sellers');
}

export const getSellerById = (id) => {
    return api.get(`/sellers/${id}`);
}

export const newSeller = (data) => {
    return api.post('/sellers', data);
}

export const editSeller = (id, data) => {
    return api.patch(`/sellers/${id}`, data);
}