<template>
    <div class="section">
        <div class="section-header card">
            <h2 class="section-header__title">Regions</h2>
            <p class="section-header__count">{{ regions.length }} regions</p>
            <div class="list-buttons">
                <v-button @click="editId = null; regionModalVisible = true">Add new</v-button>
            </div>
        </div>

        <div class="card card_no-padding">
            <v-table
                :headers="headers"
                :items="regions"
                :loading="loading"
                @edit="showEditModal"
                @delete="destroyRegion"
            />
        </div>

        <region-modal
            v-if="regionModalVisible"
            :id="editId"
            @close-modal="regionModalVisible = false"
        />
    </div>
</template>

<script>
import { ref } from 'vue';

import { indexRegions, destroyRegion } from "../api/additional";

import vInput from '../components/UI/Input.vue';
import vSelect from '../components/UI/Select.vue';
import vButton from '../components/UI/Button.vue';
import vTable from '../components/UI/Table.vue';
import RegionModal from "../components/Modals/RegionModal";

export default {
    name: "Regions",
    components: {
        vInput,
        vSelect,
        vButton,
        vTable,
        RegionModal,
    },
    setup() {
        const headers = [
            {
                type: 'standard',
                label: 'Name',
                sorting: true,
                filter: true,
                field: 'name',
            },
            {
                type: 'standard',
                label: 'Alias',
                sorting: true,
                filter: true,
                field: 'alias',
            },
            {
                type: 'standard',
                label: 'Currency',
                sorting: false,
                filter: false,
                field: 'currency',
            },
            {
                type: 'actions',
                label: null,
                sorting: false,
                filter: false,
                field: null,
            },
        ];
        const loading = ref(true);
        const regions = ref([]);
        const regionModalVisible = ref(false);

        indexRegions().then((response) => {
            regions.value = response.data;
            loading.value = false;
        });

        const editId = ref(null);
        const showEditModal = (id) => {
            editId.value = id;
            regionModalVisible.value = true;
        }

        return {
            headers,
            regions,
            loading,
            regionModalVisible,
            showEditModal,
            editId,
            destroyRegion,
        };
    },
};
</script>

<style lang="scss">

</style>