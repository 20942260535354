<template>
    <div class="menu">
        <div class="menu-item" v-for="(item, index) in menu" :key="index">
            <router-link :to="item.link" class="menu-item__label menu-item__link" v-if="!item.submenu">{{ item.label }}</router-link>
            <div class="menu-item__label menu-item__label_parent" :class="{'menu-item__label_active': item.active}" v-else @click="toggleMenu(index)">{{ item.label }}</div>

            <transition name="fade" mode="out-in">
                <div class="menu-item__submenu" v-if="item.submenu && item.active">
                    <router-link :to="subitem.link" class="menu-item__submenu-item" v-for="(subitem, index) in item.submenu" :key="index">{{ subitem.label }}</router-link>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue';

export default {
    name: "Menu",
    setup() {
        const menu = ref([
            // {
            //     label: 'Resources',
            //     active: false,
            //     submenu: [
            //         {
            //             label: 'Accounts',
            //             active: false,
            //             link: '/resources/accounts',
            //         },
            //     ],
            // },
            {
                label: 'Products',
                active: false,
                link: '/products',
            },
            {
                label: 'Additional',
                active: false,
                submenu: [
                    {
                        label: 'Games',
                        active: false,
                        link: '/additional/games',
                    },
                    {
                        label: 'Regions',
                        active: false,
                        link: '/additional/regions',
                    },
                    {
                        label: 'Sections',
                        active: false,
                        link: '/additional/sections',
                    },
                ],
            },
            {
                label: 'Requests',
                active: false,
                submenu: [
                    {
                        label: 'Orders',
                        active: false,
                        link: '/orders',
                    },
                    {
                        label: 'Buy',
                        active: false,
                        link: '/buy-requests',
                    },
                    {
                        label: 'Custom',
                        active: false,
                        link: '/custom-requests',
                    },
                    {
                        label: 'Sell',
                        active: false,
                        link: '/sell-requests',
                    },
                    {
                        label: 'Contact us',
                        active: false,
                        link: '/contact-us-requests',
                    },
                ],
            },
            {
                label: 'People',
                active: false,
                submenu: [
                    {
                        label: 'Admins',
                        active: false,
                        link: '/admins',
                    },
                    {
                        label: 'Users',
                        active: false,
                        link: '/users',
                    },
                    {
                        label: 'Sellers',
                        active: false,
                        link: '/sellers',
                    },
                ],
            },
            {
                label: 'Promocodes',
                active: false,
                link: '/promocodes',
            },
        ]);

        const toggleMenu = (i) => {
            menu.value[i].active = !menu.value[i].active;
        }

        return { menu, toggleMenu };
    },
};
</script>

<style lang="scss">
    .menu-item__label {
        font-size: $m1;
        color: $type-light-dark;
        user-select: none;
        display: block;
        padding: 14px 0 14px 20px;
        cursor: pointer;
        &:hover {
            color: $type-dark;
        }
    }

    .menu-item__label_parent {
        position: relative;
        &:hover {
            &:after {
                border-color: $type-dark transparent transparent transparent;
            }
        }
        &:after {
            @extend %background;
            content: '';
            display: block;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 5px 5px 0 5px;
            border-color: $type-light-dark transparent transparent transparent;
            position: absolute;
            right: 15px;
            top: calc(50% - 2.5px);
            background-image: url('~@/assets/svg/menu-arrow.svg');
            transition: transform .4s ease;
        }
    }

    .menu-item__label_active {
        color: $type-dark;
        &:after {
            border-color: $type-dark transparent transparent transparent;
            transform: rotate(180deg);
        }
    }

    .menu-item__link {
        &:hover {
            background-color: $main-light-gray;
            box-shadow: inset 4px 0 0 0 $type-light-gray;
        }
    }

    .menu-item__submenu-item {
        font-size: $m1;
        color: $type-light-dark;
        user-select: none;
        display: block;
        padding: 14px 0 14px 40px;
        &:hover {
            color: $type-dark;
            background-color: $main-light-gray;
            box-shadow: inset 4px 0 0 0 $type-light-gray;
        }
    }
</style>