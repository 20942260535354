<template>
    <teleport to="#modals">
        <div class="modal-wrapper">
            <div class="modal">
                <h3 class="modal__title">
                    <slot name="title"/>
                </h3>
                <div class="modal__content">
                    <slot name="content"/>
                </div>
                <img src="@/assets/svg/modal-close.svg" alt="" class="modal__close" @click="hide">
            </div>
            <div class="modal-shadow" @click="hide"/>
        </div>
    </teleport>
</template>

<script>
export default {
    name: "Modal",
    setup(props, context) {
        const hide = () => {
            context.emit('close-modal');
        }

        return {
            hide,
        }
    }
}
</script>

<style lang="scss">
    .modal-wrapper {
        @extend %flex-jcc-aic;
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    .modal {
        background-color: $main-white;
        padding: 15px;
        width: 600px;
        position: relative;
        z-index: 2;
    }

    .modal__title {
        margin-bottom: 20px;
    }

    .modal__subtitle {
        font-size: $st1;
        color: $button-dark-blue;
        margin-bottom: 20px;
    }

    .modal__close {
        @extend %pointer-no-select;
        position: absolute;
        top: 15px;
        right: 15px;
        opacity: .6;
        &:hover {
            opacity: 1;
        }
    }

    .modal__divider {
        height: 1px;
        background-color: $main-light-gray;
        margin: 15px 0;
    }

    .modal-shadow {
        position: absolute;
        height: 100%;
        width: 100%;
        background-color: rgba(0, 0, 0, .2);
    }
</style>