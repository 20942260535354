<template>
    <modal>
        <template v-slot:title>New promocode</template>
        <template v-slot:content>
            <div class="row">
                <div class="col-xl-6">
                    <div class="form-group">
                        <label class="label">Code</label>
                        <v-input v-model="data.code"/>
                    </div>
                </div>
                <div class="col-xl-6">
                    <div class="form-group">
                        <label class="label">Sale</label>
                        <v-input v-model="data.sale"/>
                    </div>
                </div>
            </div>
            <v-button class="form-submit" center @click="submit" :disabled="loading">Submit</v-button>
        </template>
    </modal>
</template>

<script>
import { storePromocode, showPromocode, updatePromocode } from "../../api/promocodes";
import { ref } from "vue";

import Modal from "./Modal";
import vInput from "../UI/Input";
import vCheckbox from "../UI/Checkbox";
import vButton from "../UI/Button";

export default {
    name: "PromocodeModal",
    components: {
        Modal,
        vInput,
        vCheckbox,
        vButton,
    },
    emits: ['close'],
    props: {
        id: {
            type: String,
        },
    },
    setup(props, context) {
        const edit = !!props.id;
        const data = ref({
            code: '',
            sale: '',
        });
        const loading = ref(false);

        if (edit) {
            showPromocode(props.id).then((response) => {
                data.value = response.data;
            });
        }

        const submit = () => {
            loading.value = true;
            if (edit) {
                updatePromocode(props.id, data.value).then(() => {
                    context.emit('close-modal');
                }).catch(() => {}).finally(() => {
                    loading.value = false;
                });
            } else {
                storePromocode(data.value).then(() => {
                    context.emit('close-modal');
                }).catch(() => {}).finally(() => {
                    loading.value = false;
                });
            }
        };

        return {
            data,
            submit,
        };
    },
}
</script>

<style scoped>

</style>