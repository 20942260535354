<template>
    <div class="input-wrapper">
        <input class="input" :class="{'input_disabled': disabled}" :type="type" :placeholder="placeholder" :autocomplete="autocomplete" v-model="val"/>
        <div class="input-units" v-if="units">{{ units }}</div>
    </div>
</template>

<script>
import { computed } from 'vue';

export default {
    name: "Input",
    emits: ['update:modelValue'],
    props: {
        modelValue: {
            type: [String, Number],
        },
        placeholder: {
            type: String,
        },
        type: {
            type: String,
            default: 'text',
        },
        disabled: {
            type: Boolean,
        },
        units: {
            type: String,
        },
        autocomplete: {
            type: String,
            default: 'on',
        }
    },
    setup(props, context) {
        const val = computed({
            get: () => props.modelValue,
            set: (newVal) => context.emit('update:modelValue', newVal),
        });

        return { val };
    },
};
</script>

<style lang="scss">
    .input-wrapper {
        position: relative;
    }

    .input {
        width: 100%;
        padding: 8px 10px;
        border: 1px solid $main-medium-gray;
        border-radius: $input;
        font-family: 'Inter', sans-serif;
        font-size: $t3;
    }

    .input[type="date"], .input[type="datetime-local"] {
        height: 35px;
    }

    .input_disabled {
        pointer-events: none;
        user-select: none;
        background-color: $main-light-gray;
        color: $type-light-dark;
    }

    .input-units {
        font-size: $t3;
        color: $type-light-gray;
        padding: 9.5px 9px;
        right: 1px;
        top: 1px;
        background-color: $main-white;
        line-height: 100%;
        position: absolute;
    }
</style>