export default {
    namespaced: true,
    state: {
        data: {},
    },
    mutations: {
        set: (state, payload) => {
            state.data = payload;
        },
        unset: (state, id) => {
            let drafts = JSON.parse(JSON.stringify(state.data));
            drafts = drafts.filter((draft) => draft.id !== id);
            state.data = drafts;
        },
        clear: (state) => {
            state.data = {};
        },
    },
}
