<template>
    <div class="tags-manager">
        <div class="tags-new">
            <div class="form-input-btn">
                <v-input v-model="data.tag"/>
                <v-button :disabled="!data.tag" @click="submit">Add new</v-button>
            </div>
        </div>

        <div class="tags-selected" v-if="selectedTags.length">
            <p class="tags-selected__title card__subtitle">Selected tags</p>
            <div class="tags">
                <div class="tag" v-for="tag in selectedTags" :key="tag.id" @click="remove(tag)">{{ tag.tag }}</div>
            </div>
        </div>

        <div class="tags-available" v-if="filteredTags.length">
            <p class="tags-available__title card__subtitle">Available tags</p>
            <div class="tags">
                <div class="tag" v-for="tag in filteredTags" :key="tag.id" @click="select(tag)">{{ tag.tag }}</div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, computed } from "vue";

import { getTags, newTag } from "@/api/tags";

import vInput from "@/components/UI/Input.vue";
import vButton from "@/components/UI/Button.vue";

export default {
    name: "Tags",
    components: {
        vInput,
        vButton,
    },
    props: ['modelValue'],
    emits: ['update:modelValue'],
    setup(props, context) {
        const data = ref({
            tag: '',
        });
        const availableTags = ref([]);

        let selectedTags = computed({
            get: () => props.modelValue,
            set: (newVal) => context.emit('update:modelValue', newVal),
        });

        getTags().then((response) => {
            availableTags.value = response.data;
        });

        const filteredTags = computed(() => {
            return availableTags.value.filter((tag) => {
                const query = data.value.tag.toLowerCase();
                const search = tag.tag.toLowerCase();
                return search.indexOf(query) >= 0 && selectedTags.value.every((selectedTag) => selectedTag.id !== tag.id);
            });
        });

        const select = (tag) => {
            data.value.tag = '';
            selectedTags.value.push(tag);
        }

        const remove = (tag) => {
            selectedTags.value = selectedTags.value.filter((selectedTag) => selectedTag.id !== tag.id);
        }

        const submit = () => {
            newTag(data.value).then((response) => {
                availableTags.value.push(response.data);
                selectedTags.value.push(response.data);
                data.value.tag = '';
            });
        }

        return { data, filteredTags, selectedTags, select, remove, submit };
    }
}
</script>

<style lang="scss">
    .tags-manager {
        margin-bottom: -10px;
    }

    .tags-new {
        margin-bottom: 20px;
    }

    .tags-selected {
        margin-bottom: 10px;
    }

    .tags-selected__title {
        margin-bottom: 10px;
    }

    .tags-available__title {
        margin-bottom: 10px;
    }

    .tags {
        @extend %flex-aic;
        flex-wrap: wrap;
    }

    .tag {
        @extend %pointer-no-select;
        font-size: $t3;
        padding: 6px 12px;
        border: 1px solid $accent-light-blue;
        border-radius: 52px;
        margin-bottom: 10px;
        &:not(:last-child) {
            margin-right: 10px;
        }
    }
</style>