<template>
    <div class="section">
        <div class="section-header card">
            <div class="section-header__title">Main</div>
        </div>

        <div class="card">
            <h3 class="card__title">Recent operations</h3>
            <div class="operation" v-for="operation in operations" :key="operation.id">
                <p class="operation__label">
                    <b>{{ operation.initiator }}</b> {{ actions[operation.action].label }}
                    <router-link
                        :to="actions[operation.action].link + operation.target"
                        v-if="actions[operation.action].showTarget"
                    >
                        ({{ operation.target }})
                    </router-link>
                </p>
                <p class="operation__datetime">{{ timestamp(operation.created_at) }}</p>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from "vue";

import { timestamp } from "../filters";
import { resourcesCheck } from "../api/kamch";
import { indexOperations } from "../api/operations";

export default {
    name: "Main",
    setup() {
        resourcesCheck().then((response) => {
            console.log(response);
        });

        const operations = ref([]);
        indexOperations().then((response) => {
            operations.value = response.data;
        });

        const actions = {
            login: {
                label: 'logged in',
                showTarget: false,
            },
            'product:new': {
                label: 'created product',
                showTarget: true,
                link: '/products/',
            },
            'product:edit': {
                label: 'edited product',
                showTarget: true,
                link: '/products/',
            },
            'admin:new': {
                label: 'created new admin account',
                showTarget: false,
            },
        };

        return {
            timestamp,
            operations,
            actions,
        };
    },
}
</script>

<style lang="scss" scoped>
.operation {
    @extend %flex-jcsb-aic;
}
</style>