<template>
    <div class="section">
        <div class="section-header card">
            <div class="section-header__title">Games</div>
            <div class="section-header__count">{{ games.length }} games</div>
            <div class="list-buttons">
                <v-button @click="gameModalVisible = true">Add new</v-button>
            </div>
        </div>

        <div class="card card_no-padding">
            <v-table
                :headers="headers"
                :items="games"
                :loading="gamesLoading"
                @edit="showEditModal"
                @delete="destroyGame"
            />
        </div>

        <game-modal
            v-if="gameModalVisible"
            :id="editId"
            @close-modal="gameModalVisible = false"
        />
    </div>
</template>

<script>
import { ref } from 'vue';

import { getGames, destroyGame } from "../api/additional";

import vButton from '../components/UI/Button.vue';
import vTable from '../components/UI/Table.vue';
import GameModal from "../components/Modals/GameModal";

export default {
    name: "Games",
    components: {
        vButton,
        vTable,
        GameModal,
    },
    setup() {
        const headers = [
            {
                type: 'checkbox',
                label: null,
                sorting: false,
                filter: false,
                field: null,
            },
            {
                type: 'standard',
                label: 'Name',
                sorting: true,
                filter: true,
                field: 'name',
            },
            {
                type: 'standard',
                label: 'Alias',
                sorting: true,
                filter: true,
                field: 'alias',
            },
            {
                type: 'image',
                label: 'Image',
                sorting: false,
                filter: false,
                field: 'image',
            },
            {
                type: 'image',
                label: 'Background image',
                sorting: false,
                filter: false,
                field: 'background_image',
            },
            {
                type: 'boolean',
                label: 'Public',
                sorting: false,
                filter: false,
                field: 'public',
            },
            {
                type: 'actions',
                label: null,
                sorting: false,
                filter: false,
                field: null,
            },
        ];

        const games = ref([]);
        const gamesLoading = ref(true);
        getGames().then((response) => {
            games.value = response.data;
            gamesLoading.value = false;
        });

        const gameModalVisible = ref(false);

        const editId = ref(null);
        const showEditModal = (id) => {
            editId.value = id;
            gameModalVisible.value = true;
        }

        return {
            headers,
            games,
            gamesLoading,
            gameModalVisible,
            editId,
            showEditModal,
            destroyGame,
        };
    },
}
</script>

<style lang="scss">

</style>