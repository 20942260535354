<template>
    <div class="login">
        <div class="login-form">
            <img src="@/assets/svg/logo.svg" class="login__logo" alt=""/>
            <form action="#" @submit.prevent="signIn(credentials)">
                <div class="form-group">
                    <label for="" class="label">Email</label>
                    <v-input v-model="credentials.email" :disabled="loading" autocomplete="email"/>
                </div>
                <div class="form-group">
                    <label for="" class="label">Password</label>
                    <v-input type="password" v-model="credentials.password" :disabled="loading" autocomplete="current-password"/>
                </div>
                <v-button type="submit" center :disabled="loading">Login</v-button>
            </form>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue';
import { useStore } from 'vuex';

import api from "@/api";

import { login } from "@/api/auth";

import vInput from '@/components/UI/Input.vue';
import vButton from '@/components/UI/Button.vue'

export default {
    name: "Login",
    components: {
        vInput,
        vButton,
    },
    setup(props, context) {
        const credentials = ref({
            email: '',
            password: '',
        });
        const loading = ref(false);

        const store = useStore();

        const signIn = () => {
            loading.value = true;
            login(credentials.value).then((response) => {
                store.commit('user/set', response.data.user);
                api.defaults.headers.Authorization = `Bearer ${response.data.token}`;
                localStorage.setItem('token', response.data.token);
                store.commit('drafts/set', response.data.drafts);
                context.emit('login');
            }).catch((error) => {
                console.log(error.response);
                loading.value = false;
            });
        };

        return { credentials, loading, signIn };
    },
}
</script>

<style lang="scss">
    .login {
        @extend %flex-jcc-aic;
        height: 100vh;
        background-color: $main-light-gray;
    }

    .login__logo {
        margin: 0 auto 20px;
    }

    .login-form {
        @extend %card;
        width: 320px;
        padding: 20px;
    }
</style>