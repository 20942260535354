import { createStore } from 'vuex';

import user from "./modules/user";
import notifications from "./modules/notifications";
import errors from "./modules/errors";
import drafts from "./modules/drafts";

const store = createStore({
    state: {
        kamchReady: false,
    },
    mutations: {
        updateKamchReady: (state, payload) => {
            state.kamchReady = payload;
        },
    },
    modules: {
        user,
        notifications,
        errors,
        drafts,
    },
});

export default store;
