<template>
    <template v-if="!loading">
        <login v-if="!user" @login="initPusher"/>
        <template v-else-if="!error">
            <div class="app-wrapper">
                <v-aside/>
                <main class="main">
                    <v-header @logout="destroyPusher"/>
                    <router-view v-slot="{ Component }" :key="$route.fullPath">
                        <component :is="Component"/> <!-- TODO: fix warning (watch console) -->
                    </router-view>
                </main>
                <notifications-wrapper/>
            </div>
        </template>
        <error-wrapper v-else :code="error.code" :message="error.message"/>
    </template>
</template>

<script>
import Pusher from "pusher-js";
import { ref, computed } from 'vue';
import { useStore } from "vuex";

import { cookieInit, check } from "./api/auth";
import { healthCheck } from "./api/kamch";

import Login from './views/Login.vue';
import vAside from './components/Aside.vue';
import vHeader from './components/Header.vue';
import NotificationsWrapper from './components/Notifications/NotificationsWrapper.vue';
import ErrorWrapper from "./views/ErrorWrapper.vue";

export default {
    name: 'app',
    components: {
        Login,
        vAside,
        vHeader,
        NotificationsWrapper,
        ErrorWrapper,
    },
    setup() {
        const store = useStore();
        const loading = ref(true);

        const user = computed(() => store.state.user.me);

        let pusher;
        let presence;
        let orders;
        let requests;
        let customRequests;
        let sellRequests;

        cookieInit().then(() => {
            check().then((response) => {
                if (response.data) {
                    store.commit('user/set', response.data.user);
                    store.commit('drafts/set', response.data.drafts);
                    initPusher();
                }
                loading.value = false;
            });
        });

        const initPusher = () => {
            pusher = new Pusher('a82046d004e9ddc13a3d', {
                cluster: 'eu',
                authEndpoint: process.env.VUE_APP_API_BASE + '/api/broadcasting/auth',
                auth: {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    }
                },
            });

            presence = pusher.subscribe('presence-main');
            presence.bind('pusher:subscription_succeeded', function (members) {
                store.commit('user/setList', members.members);
            });
            presence.bind('pusher:member_added', function (member) {
                store.commit('user/setList', presence.members.members);

                const id = Math.floor(Math.random() * 10000000);
                store.commit('notifications/new', {
                    id: id,
                    type: 'Activity',
                    message: `${member.info.name} has connected`,
                });
            });
            presence.bind('pusher:member_removed', function (member) {
                store.commit('user/setList', presence.members.members);

                const id = Math.floor(Math.random() * 10000000);
                store.commit('notifications/new', {
                    id: id,
                    type: 'Activity',
                    message: `${member.info.name} has disconnected`,
                });
            });

            orders = pusher.subscribe('private-orders');
            orders.bind('orders:new', function(order) {
                const id = Math.floor(Math.random() * 10000000);
                store.commit('notifications/new', {
                    id: id,
                    type: 'Orders',
                    message: `New order`,
                    link: `/orders/${order.order.id}`
                });
            });

            requests = pusher.subscribe('private-requests');
            requests.bind('requests:new', function(data) {
                const id = Math.floor(Math.random() * 10000000);
                store.commit('notifications/new', {
                    id: id,
                    type: 'Requests',
                    message: `New request`,
                    link: `/requests/${data.request.id}`
                });
            });

            customRequests = pusher.subscribe('private-custom-requests');
            customRequests.bind('custom-requests:new', function(data) {
                const id = Math.floor(Math.random() * 10000000);
                store.commit('notifications/new', {
                    id: id,
                    type: 'Requests',
                    message: `New custom request`,
                    link: `/custom-requests/${data.customRequest.id}`
                });
            });

            sellRequests = pusher.subscribe('private-sell-requests');
            sellRequests.bind('sell-requests:new', function(data) {
                const id = Math.floor(Math.random() * 10000000);
                store.commit('notifications/new', {
                    id: id,
                    type: 'Requests',
                    message: `New sell request`,
                    link: `/sell-requests/${data.sellRequest.id}`
                });
            });
        };

        const destroyPusher = () => {
            presence.unsubscribe();
            pusher.disconnect();
        };

        const error = computed(() => store.state.errors.data);

        healthCheck().then((response) => {
            store.commit('updateKamchReady', response.data === 'ok');
        });

        return { user, loading, initPusher, destroyPusher, error };
    },
};
</script>

<style lang="scss">
@import '~@/scss/_scaffoldings.scss';
@import '~@/css/bootstrap-grid.min.css';
</style>
