import api from "@/api";

export const getAdmins = () => {
    return api.get('/admins');
};

export const newAdmin = (data) => {
    return api.post('/admins', data);
}

