<template>
    <div class="loader">
        <img src="@/assets/svg/preloader.svg" alt="" class="loader__svg">
    </div>
</template>

<script>
export default {
    name: "Loader"
}
</script>

<style lang="scss">
    .loader {
        @extend %flex-jcc-aic;
        height: calc(100vh - 60px);
        &__svg {
            width: 100px;
        }
    }
</style>