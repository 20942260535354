<template>
    <aside class="aside">
        <div class="aside-logo">
            <router-link to="/">
                <img src="@/assets/svg/logo.svg" alt="" draggable="false">
            </router-link>
        </div>

        <v-menu/>
    </aside>
</template>

<script>
import vMenu from "@/components/Menu.vue";

export default {
    name: "Aside",
    components: {
        vMenu,
    },
};
</script>

<style lang="scss">
    .aside {
        position: fixed;
        left: 0;
        top: 0;
        bottom: 0;
        width: 240px;
        background-color: $main-white;
        box-shadow: $block1;
    }

    .aside-logo {
        user-select: none;
        padding: 15px 50px;
        margin-bottom: 25px;
    }
</style>