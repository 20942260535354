import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import Toast from "vue-toastification";

const showdown = require('showdown');
window.converter = new showdown.Converter({
    headerLevelStart: 3,
});

const app = createApp(App);
app.use(store);
app.use(router);
app.use(Toast);
app.mount('#app');