<template>
    <div class="section" v-if="!loading">
        <div class="section-header card">
            <h2 class="section-header__title">{{ route.meta.title }}</h2>
        </div>

        <div class="row">
            <div class="col-xl-6">
                <div class="card">
                    <h3 class="card__title">Main info</h3>

                    <p class="card__info"><b>Name:</b> {{ request.name }}</p>
                    <p class="card__info"><b>Status:</b> {{ request.status }}</p>
                    <p class="card__info"><b>Created:</b> {{ timestamp(request.created_at) }}</p>
                </div>

                <div class="card" v-if="request.user">
                    <h3 class="card__title">User</h3>
                    <p class="card__info"><b>Nickname:</b> {{ request.user.nickname }}</p>
                    <p class="card__info"><b>Email:</b> {{ request.user.email }}</p>
                </div>

                <div class="card">
                    <h3 class="card__title">Contacts</h3>

                    <p class="card__info" v-for="contact in request.contacts" :key="contact.id">
                        <span style="text-transform: capitalize; font-weight:bold;">{{ contact.type }}:</span>
                        {{ contact.value }}
                        {{ contact.default ? '(default)' : '' }}
                    </p>
                </div>
            </div>
            <div class="col-xl-6">
                <div class="card">
                    <h3 class="card__title">Product</h3>
                    <product-card :data="request.product"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from "vue";
import { useRoute } from "vue-router";

import { timestamp } from "../filters";
import { getRequestById } from "../api/buyRequests";

import ProductCard from "../components/ProductCard";

export default {
    name: "Request",
    components: {
        ProductCard,
    },
    setup() {
        const route = useRoute();
        const id = route.params.id;

        const loading = ref(true);
        const request = ref({});

        getRequestById(id).then((response) => {
            request.value = response.data;
            loading.value = false;
        });

        return { loading, route, request, timestamp };
    },
}
</script>