import api from '@/api';

export const cookieInit = () => {
    return api.get(process.env.VUE_APP_API_BASE + '/sanctum/csrf-cookie');
}

export const login = (credentials) => {
    return api.post('/admins/login', {
        email: credentials.email,
        password: credentials.password,
    });
}

export const logout = () => {
    return api.post('/admins/logout');
}

export const check = () => {
    return api.post('/admins/check');
}