export const makeAlias = (title) => {
    return title
        .toString()
        .toLowerCase()
        .replace(/\s+/g, '-')
        .replace(/[^\w\-]+/g, '')
        .replace(/\-\-+/g, '-')
        .replace(/^-+/, '')
        .replace(/-+$/, '');
};

export const getNested = (object, key) => {
    const path = key.split('.');
    let result = object;

    path.forEach((key) => {
        result = result[key];
    });

    return result;
};

export const filterObjectByFalsy = (object) => {
    return Object.fromEntries(Object.entries(object).filter(([_, v]) => v));
}