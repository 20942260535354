import { createRouter, createWebHistory } from 'vue-router';

import Main from '../views/Main.vue';
import List from '../views/List.vue';
import Product from '../views/Product.vue';
import Regions from "../views/Regions.vue";
import Games from "../views/Games.vue";
import Sections from "../views/Sections.vue";
import Resource from "../views/Resource.vue";
import Seller from "../views/Seller";
import Admins from "../views/Admins";
import Order from "../views/Order";
import BuyRequest from "../views/BuyRequest";
import CustomRequest from "../views/CustomRequest";
import SellRequest from "../views/SellRequest";
import Products from "../views/Products";
import ContactUsRequest from "../views/ContactUsRequest";
import Promocodes from "../views/Promocodes";

const routes = [
    // main
    {
        path: '/',
        name: 'main',
        component: Main,
    },

    // resources
    {
        path: '/resources/accounts',
        name: 'resources:accounts',
        component: List,
        props: {
            alias: 'resources:accounts',
        },
        meta: {
            title: 'Resources [Accounts]',
        },
    },
    {
        path: '/resources/accounts/new',
        name: 'resources:accounts:new',
        component: Resource,
        props: {
            type: 'account',
            mode: 'new',
        },
        meta: {
            title: 'New resource [Account]',
        },
    },
    {
        path: '/resources/accounts/:id',
        name: 'resources:accounts:edit',
        component: Resource,
        props: {
            type: 'account',
            mode: 'edit',
        },
        meta: {
            title: 'Edit resource [Account]',
        },
    },

    // products
    {
        path: '/products',
        name: 'products',
        component: Products,
        props: {
            alias: 'products',
        },
        meta: {
            title: 'Products',
        },
    },
    {
        path: '/products/new',
        name: 'products:new',
        component: Product,
        props: {
            type: 'account',
            mode: 'new',
        },
        meta: {
            title: 'New product',
        },
    },
    {
        path: '/products/:id',
        name: 'products:edit',
        component: Product,
        props: {
            type: 'account',
            mode: 'edit',
        },
        meta: {
            title: 'Edit product',
        },
    },

    // additional
    {
        path: '/additional/regions',
        name: 'regions',
        component: Regions,
    },
    {
        path: '/additional/games',
        name: 'games',
        component: Games,
    },
    {
        path: '/additional/sections',
        name: 'sections',
        component: Sections,
    },

    // admins
    {
        path: '/admins',
        name: 'admins',
        component: Admins,
        meta: {
            title: 'Admins',
        },
    },

    // users
    {
        path: '/users',
        name: 'users',
        component: List,
        props: {
            alias: 'users',
            link: false,
        },
        meta: {
            title: 'Users',
        },
    },

    // sellers
    {
        path: '/sellers',
        name: 'sellers',
        component: List,
        props: {
            alias: 'sellers',
        },
        meta: {
            title: 'Sellers',
        },
    },
    {
        path: '/sellers/new',
        name: 'sellers:new',
        component: Seller,
        props: {
            mode: 'new',
        },
        meta: {
            title: 'New seller',
        },
    },
    {
        path: '/sellers/:id',
        name: 'sellers:edit',
        component: Seller,
        props: {
            mode: 'edit',
        },
        meta: {
            title: 'Edit seller',
        },
    },

    // orders
    {
        path: '/orders',
        name: 'orders',
        component: List,
        props: {
            alias: 'orders',
            link: true,
        },
        meta: {
            title: 'Orders',
        },
    },
    {
        path: '/orders/:id',
        name: 'order:view',
        component: Order,
        meta: {
            title: 'Order',
        },
    },

    // buy requests
    {
        path: '/buy-requests',
        name: 'buy-requests',
        component: List,
        props: {
            alias: 'buy-requests',
            link: true,
        },
        meta: {
            title: 'Requests',
        },
    },
    {
        path: '/buy-requests/:id',
        name: 'buy-requests:view',
        component: BuyRequest,
        meta: {
            title: 'Request',
        },
    },

    // custom-requests
    {
        path: '/custom-requests',
        name: 'custom-requests',
        component: List,
        props: {
            alias: 'custom-requests',
            link: true,
        },
        meta: {
            title: 'Custom requests',
        },
    },
    {
        path: '/custom-requests/:id',
        name: 'custom-requests:view',
        component: CustomRequest,
        meta: {
            title: 'Custom request',
        },
    },

    // sell-requests
    {
        path: '/sell-requests',
        name: 'sell-requests',
        component: List,
        props: {
            alias: 'sell-requests',
            link: true,
        },
        meta: {
            title: 'Sell requests',
        },
    },
    {
        path: '/sell-requests/:id',
        name: 'sell-requests:view',
        component: SellRequest,
        meta: {
            title: 'Sell request',
        },
    },

    // contact-us-requests
    {
        path: '/contact-us-requests',
        name: 'contact-us-requests',
        component: List,
        props: {
            alias: 'contact-us-requests',
            link: true,
        },
        meta: {
            title: 'Contact us requests',
        },
    },
    {
        path: '/contact-us-requests/:id',
        name: 'contact-us-requests:view',
        component: ContactUsRequest,
        meta: {
            title: 'Contact us request',
        },
    },

    // promocodes
    {
        path: '/promocodes',
        name: 'promocodes',
        component: Promocodes,
        meta: {
            title: 'Promocodes',
        },
    },
    // test
    // {
    //     path: '/test',
    //     component: Test,
    // },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

export default router;
