export default {
    namespaced: true,
    state: {
        data: null,
    },
    mutations: {
        set: (state, payload) => {
            state.data = payload;
        },
    },
}