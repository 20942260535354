<template>
    <modal>
        <template v-slot:title>New region</template>
        <template v-slot:content>
            <div class="row">
                <div class="col-xl-6">
                    <div class="form-group">
                        <label class="label">Name</label>
                        <v-input v-model="data.name"/>
                    </div>
                </div>
                <div class="col-xl-6">
                    <div class="form-group">
                        <label class="label">Alias</label>
                        <v-input v-model="data.alias"/>
                    </div>
                </div>
                <div class="col-xl-6">
                    <div class="form-group">
                        <label class="label">Currency</label>
                        <v-select :options="[{id: 'USD', name: 'USD'}, {id: 'EUR', name: 'EUR'}]" v-model="data.currency"/>
                    </div>
                </div>
            </div>
            <v-button class="form-submit" center @click="submit" :disabled="loading">Submit</v-button>
        </template>
    </modal>
</template>

<script>
import draggable from "vuedraggable";

import { ref } from "vue";
import { storeRegion, showRegion, updateRegion } from "../../api/additional";

import Modal from "./Modal";
import vInput from "../UI/Input";
import vSelect from "../UI/Select";
import vCheckbox from "../UI/Checkbox";
import vButton from "../UI/Button";

export default {
    name: "SectionModal",
    components: {
        draggable,
        Modal,
        vInput,
        vSelect,
        vCheckbox,
        vButton,
    },
    emits: ['close'],
    props: {
        id: {
            type: String,
        },
    },
    setup(props, context) {
        const data = ref({
            name: '',
            alias: '',
            currency: '',
        });
        const edit = !!props.id;

        if (edit) {
            showRegion(props.id).then((response) => {
                data.value = response.data;
            });
        }

        const loading = ref(false);
        const submit = () => {
            loading.value = true;
            if (edit) {
                updateRegion(props.id, data.value).then(() => {
                    context.emit('close-modal');
                }).catch(() => {}).finally(() => {
                    loading.value = false;
                });
            } else {
                storeRegion(data.value).then(() => {
                    context.emit('close-modal');
                }).catch(() => {}).finally(() => {
                    loading.value = false;
                });
            }
        };

        return { data, loading, submit };
    },
};
</script>