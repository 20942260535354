export default {
    namespaced: true,
    state: {
        data: [],
    },
    mutations: {
        new: (state, payload) => {
            state.data.push(payload);
        },
        delete: (state, id) => {
            state.data = [...state.data.filter((item) => item.id !== id)];
        },
    }
}